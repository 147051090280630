import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, filter, map, of, switchMap, take, throwError } from 'rxjs';
import { CONSTANT } from '../constants/constant';
import { dataroom_messages } from 'src/app/constants/dataroom';
import { Router } from '@angular/router';
import { SnackService } from 'src/app/services/snack.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private router: Router,
        private snackService: SnackService,
        private authService: AuthService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // Get the auth token from the service.
        const authToken = CONSTANT.getToken();
        // Clone the request and replace the original headers with
        // cloned headers, updated with the authorization.

        let clonedReq = this.addToken(req)
        // send cloned request with header to the next handler.
        return next.handle(clonedReq).pipe(switchMap((res: any) => {
            if (res?.body?.hasOwnProperty('error') && res?.body?.error) {
                throw res
            }
            if (res?.body?.hasOwnProperty('object') && (res?.body?.object == false || res?.body?.object == null)) {
                throw res
            }
            return of(res);
        }),
            catchError((error) => {
                if (error?.body?.messageCode === 415 && authToken) {
                    if (CONSTANT.getRememberMe() == 1) {
                        //return this.handle401Error(req, next)
                        CONSTANT.logoutDataroom();
                        this.router.navigateByUrl("/switch/corporate");
                    } else {
                        this.logout()
                    }
                }
                return throwError(() => error);
            })
        );
    }


    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.authService.refreshToken().pipe(
                switchMap((token: any) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(true);
                    return next.handle(this.addToken(request));
                }),
                catchError((e) => {
                    if ((e?.refresh || '').toLowerCase() === 'session') {
                        this.router.navigate(['auth/login'], { queryParams: { refresh: 'session', location: location.href } });
                        return e;
                    }
                    this.logout();
                    return e;
                })
            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter((val) => val),
                take(1),
                switchMap((val) => {
                    return next.handle(this.addToken(request));
                })
            );
        }
    }

    private logout() {
        if((this.router.url).endsWith("/switch/corporate")){
            CONSTANT.logout();
            this.snackService.error(dataroom_messages.session_expired);
            this.router.navigateByUrl('/auth/login');
        } else {
            CONSTANT.logoutDataroom();
            this.router.navigateByUrl('/switch/corporate');
        } 
    }

    private addToken(req: HttpRequest<any>) {
        let authToken = CONSTANT.getToken();
        if(req.url.includes('/v1/user/login/')){
            authToken = CONSTANT.getTokenLogin();
        }
        let clonedReq = req.clone();
        if (!req.url.includes('amazonaws.com') && !req.url.includes('v1/public/')) {
            req.headers.set('websource', `angular`)
            clonedReq = authToken ?
                req.clone({
                    headers: req.headers.set('Authorization', `Bearer ${authToken}`)
                        .set('websource', `angular`),
                    withCredentials: true
                }) :
                req.clone({
                    headers: req.headers.set('websource', `angular`),
                    withCredentials: true
                });
        }
        return clonedReq;
    }
}
import { Pipe, PipeTransform } from '@angular/core';
import { FILE_SIZE_FORMAT } from 'src/app/constants/constant';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform(value: number | string): string {
    return FILE_SIZE_FORMAT(value);
  }

}

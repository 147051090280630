<div mat-dialog-title class="d-flex pt-3 px-3 dc-bg-light">
    <div class="d-flex justify-content-between flex-fill">
        <div class="d-flex flex-column pe-5">
            <h2 class="typo-ps-heading-1 {{theme.title}}">{{data?.messages?.title}}</h2>
            <p class="typo-ps-body-1 m-0 dc-text-light-dark fw-normal" *ngIf="data?.messages?.subTitle">
                {{data?.messages?.subTitle}}</p>

        </div>
        <button mat-icon-button mat-dialog-close *ngIf="data?.closeable || data?.confirmation">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<mat-dialog-content *ngIf="data?.messages?.subTitles?.length" class="px-3 py-0">
    <div class="d-flex flex-column">
        <ng-container *ngIf="data?.messages?.subTitles?.length">
            <p *ngFor="let subTitle of data?.messages?.subTitles" class="typo-ps-body-1 m-0 dc-text-light-dark fw-normal">
                {{subTitle}}</p>
            <p class="typo-ps-body-1 m-0 dc-text-light-dark fw-normal" id="screentimer" style="display: none;">sdfdsfsdfsdf
                </p>
        </ng-container>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="pb-4 pt-2 px-3  dc-bg-light" [align]="align" *ngIf="!data?.confirmation && data?.closeable">
    <button mat-button mat-dialog-close cdkFocusInitial class="{{theme.cancle}}">{{data?.btns?.close?.title ||
        'Close'}}</button>
</mat-dialog-actions>

<mat-dialog-actions class="pb-4 pt-2 px-3  dc-bg-light" [align]="align" *ngIf="data?.confirmation">
    <button mat-button cdkFocusInitial class="{{theme.continue}}"
        [mat-dialog-close]="true">{{data?.btns?.continue?.title || 'Close'}}</button>
    <button mat-button mat-dialog-close class="{{theme.cancle}} ms-2">{{data?.btns?.close?.title || 'Close'}}</button>
</mat-dialog-actions>
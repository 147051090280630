import { Injectable, Injector, ViewContainerRef, createNgModuleRef, EventEmitter, NgModuleRef, ComponentRef, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from '../../environments/environment';
import { ComponentConfig, ComponentNameToLoad, LoadComponentData, ModuleConfig, ModulesConfig, StandaloneComonentConfig } from './module.config.model';


@Injectable({
  providedIn: 'any'
})

export class DynamicLoaderService {
  modulesConfig!: ModulesConfig
  standaloneConfig!: StandaloneComonentConfig
  private renderer: Renderer2;

  constructor(private injector: Injector, rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }


  private _moduleConfig = () => new Promise<{ modulesConfig: ModulesConfig, standaloneConfig: StandaloneComonentConfig }>(resolve => {
    if (this.modulesConfig) { return resolve({ modulesConfig: this.modulesConfig, standaloneConfig: this.standaloneConfig }); }
    import('./module.config.service').then(({ config, standaloneConfig }) => {
      this.modulesConfig = config;
      this.standaloneConfig = standaloneConfig;
      resolve({ modulesConfig: this.modulesConfig, standaloneConfig: this.standaloneConfig });
    })
  })

  loadComponent = ({ componentName, standalone, skipClear }: ComponentNameToLoad, vcr: ViewContainerRef, data?: LoadComponentData) => new Promise<ComponentRef<any>>(async (resolve, reject) => {
    if (!vcr?.createComponent && !environment.production) {
      return reject('ViewContainerRef Missing!')
    }
    try {
      const moduleConfig: ModuleConfig | null = standalone ? null : (await this.checkComponent(componentName));
      if (moduleConfig || standalone) {
        const componentRef: ComponentRef<any> = await this.loadComponentByModuleConfig(moduleConfig, { componentName, standalone, skipClear }, vcr, data);
        resolve(componentRef);
      } else {
        reject(`${componentName} not found in module.config.service.ts`)
      }
    } catch (e) {
      reject(e)
    }
  })


  loadComponentByModuleConfig = (moduleConfig: ModuleConfig | null, { componentName, standalone, skipClear = false }: ComponentNameToLoad, vcr: ViewContainerRef, data?: LoadComponentData) => new Promise<ComponentRef<any>>(async (resolve, reject) => {
    if (!vcr?.createComponent) {
      return reject('ViewContainerRef Missing!')
    }
    try {
      if (moduleConfig) {
        const moduleRef = await moduleConfig.load(this.injector);
        const component = await moduleConfig.components[componentName].load();
        if (!skipClear) { vcr.clear(); }
        const componentRef: ComponentRef<any> = await this.attachComponentToVCR(moduleRef, component, vcr, data, false);
        resolve(componentRef);
      } else if (standalone) {
        const componentConfig = await this.checkStandaloneComponent(componentName);
        if (componentConfig) {
          const component = await componentConfig.load();
          if (!skipClear) { vcr.clear(); }
          const componentRef: ComponentRef<any> = await this.attachComponentToVCR(null, component, vcr, data, true);
          resolve(componentRef);
        } else {
          reject(`Standalne ${componentName} not found in module.config.service.ts`)
        }
      } else {
        reject('Error!')
      }
    } catch (e) {
      reject(e)
    }
  });


  checkComponent = (componentName: string) => new Promise<ModuleConfig | null>(async (resolve) => {
    try {
      await this._moduleConfig();
      const moduleKey: keyof ModulesConfig = Object.keys(this.modulesConfig).find(k => this.modulesConfig[k].components[componentName]) as keyof ModulesConfig;

      const moduleConfig: ModuleConfig | null = (moduleKey ? this.modulesConfig[moduleKey] : null);
      if (moduleConfig?.load && moduleConfig?.components[componentName]) {
        return resolve(moduleConfig);
      }
      resolve(null);
    } catch (e) {
      resolve(null);
    }
  })

  checkStandaloneComponent = (componentName: string) => new Promise<ComponentConfig | null>(async (resolve) => {
    try {
      await this._moduleConfig();
      return resolve(this.standaloneConfig.components[componentName] || null);
    } catch (e) {
      resolve(null);
    }
  })

  loadComponentRef = (componentName: string, vcr: ViewContainerRef, data?: LoadComponentData) => new Promise(async (resolve, reject) => {
    if (!vcr?.createComponent) {
      return reject('ViewContainerRef Missing!')
    }
    try {
      const moduleConfig: ModuleConfig | null = await this.checkComponent(componentName);
      if (moduleConfig) {
        const instance = await this.loadComponentByModuleConfig(moduleConfig, { componentName }, vcr, data);
        resolve(instance);
      } else {
        reject('Error!')
      }
    } catch (e) {
      reject(e)
    }
  })


  loadComponentWithoutVCR = (componentName: string) => new Promise<{ moduleRef: NgModuleRef<any>, component: any }>(async (resolve, reject) => {
    try {
      const moduleConfig: ModuleConfig | null = await this.checkComponent(componentName);
      if (moduleConfig) {
        const moduleRef: NgModuleRef<any> = await moduleConfig.load(this.injector);
        const component = await moduleConfig.components[componentName].load();
        resolve({ moduleRef, component });
      } else {
        reject(`${componentName} not found in module.config.service.ts`)
      }
    } catch (e) {
      reject(e)
    }
  });

  attachComponentToVCR = (moduleRef: NgModuleRef<any> | null, component: any, vcr: ViewContainerRef, data?: LoadComponentData, standalone?: boolean) => new Promise<ComponentRef<any>>(async (resolve, reject) => {
    try {
      if (
        (!standalone && (!moduleRef || !component))
        || (standalone && !component)
      ) {
        throw 'moduleRef or component is missing';
      }
      if (!vcr?.createComponent) {
        throw 'ViewContainerRef Missing!'
      }
      const componentRef: ComponentRef<any> = !moduleRef ? vcr.createComponent(component, { injector: this.injector }) : vcr.createComponent(component, { ngModuleRef: moduleRef });
      if (data?.inputs && Object.keys(data?.inputs || {}).length) {
        Object.keys(data?.inputs || {}).forEach(key => {
          if (data?.inputs && data?.inputs[key]) {
            componentRef.instance[key] = data?.inputs[key];
          }
        })
      }
      if (Object.keys(data?.outputs || {}).length) {
        Object.keys(data?.outputs || {}).forEach(key => {
          if (componentRef?.instance[key] instanceof EventEmitter && data?.outputs && typeof data?.outputs[key] === 'function') {
            (componentRef?.instance[key] as EventEmitter<any>).subscribe(e => {
              if (data?.outputs) {
                data?.outputs[key](e)
              }
            })

          }
        })
      }
      if (componentRef.instance?.init && typeof componentRef.instance?.init === 'function') {
        try {
          componentRef.instance?.init();
        } catch (err) {
          console.error('dynamic-loader.service:attachComponentToVCR:componentRef.instance?.init() ', err)
        }
      }
      if (data?.classes?.length) {
        data?.classes?.filter(cls => cls)?.forEach(cls => cls?.split(" ").filter(c => c).forEach(c => this.renderer.addClass(componentRef.location.nativeElement, c)));
      }

      if (data?.attributes?.length) {
        data?.attributes?.forEach(i => this.renderer.setAttribute(componentRef.location.nativeElement, i.key, i.value));
      }
      resolve(componentRef);
    } catch (e) {
      reject(e);
      console.error('dynamic-loader.service:attachComponentToVCR ', e)
    }
  });
}




import { AfterViewInit, Directive, EventEmitter, ViewContainerRef, Input, Output } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[adHost]',
})
export class AdDirective implements AfterViewInit {

    @Input('adHost') enabled: boolean = false
    @Output() onLoadInView: EventEmitter<ViewContainerRef> = new EventEmitter();
    constructor(public viewContainerRef: ViewContainerRef) { }

    ngAfterViewInit(): void {
        if (this.enabled) {
            this.onLoadInView.emit(this.viewContainerRef);
        }
    }
}
<div *ngIf="files?.length"
    class="dc-bg-light z-index-1 bottom-0 end-0 me-3 mb-3 shadow rounded downloading-conatiner">
    <mat-accordion class="w-100">
        <mat-expansion-panel class="shadow-none dc-bg-light" [expanded]="true">
            <mat-expansion-panel-header class="px-4">
                <mat-panel-title class="">
                    <span
                        class="flex-fill typo-ps-body-3 dc-text-light-dark fw-normal d-flex align-items-center justify-content-between">
                        <span>{{uploadTitle}}</span>
                        <span (click)="cancelUpload()" role="button"
                            class="dc-text-secondary fs-6 material-icons ms-5">clear</span>
                    </span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list class="dc-bg-light-xs rounded list-max-h">
                <ng-container *ngFor="let folder of folders">
                    <div mat-subheader>
                        <p
                            class="border-bottom dc-text-secondary m-0 mb-2 pb-1 typo-ps-body-1 d-flex align-items-center">
                            <span class="material-icons fs-5 me-1 dc-text-light-dark">folder</span>
                            <span class="dc-text-secondary flex-fill d-inline-block text-truncate max-width-15">{{folder.folderPath}}</span>
                        </p>
                    </div>
                    <mat-list-item *ngFor="let file of folder.files">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center">
                                <img class="avatar-1 me-1" src="assets/images/filetypes/{{file.type.toLowerCase()}}.png"
                                    onerror="this.src = 'assets/images/filetypes/file.png'">
                                <span
                                    class="m-0 typo-ps-body-4 mb-0 flex-fill d-inline-block text-truncate text-truncate-mx-10">{{file.name}}</span>
                                <span
                                    class="border-start dc-border-light-md ms-2 px-2 py-0 typo-ps-body-4 dc-text-light-dark">{{(file?.size
                                    || 0) | fileSize}}</span>
                            </div>
                            <mat-spinner *ngIf="file.isProcessing" class="ms-3" [diameter]="15"></mat-spinner>
                            <span *ngIf="!file.isProcessing"
                                class="ms-3 dc-text-success fs-4 material-icons">check_circle </span>
                        </div>
                        <mat-progress-bar *ngIf="file.isProcessing"
                            [mode]="file.state == 'PENDING' ? 'buffer' : 'determinate'"
                            [value]="file.progress"></mat-progress-bar>
                    </mat-list-item>
                </ng-container>
            </mat-list>
        </mat-expansion-panel>
    </mat-accordion>
</div>


<div *ngIf="otherFiles?.length"
    class="position-fixed dc-bg-light z-index-1 bottom-0 end-0 me-3 mb-3 shadow rounded downloading-conatiner">
    <mat-accordion class="w-100">
        <mat-expansion-panel class="shadow-none dc-bg-light" [expanded]="true">
            <mat-expansion-panel-header class="px-4">
                <mat-panel-title class="">
                    <span
                        class="flex-fill typo-ps-body-3 dc-text-light-dark fw-normal d-flex align-items-center justify-content-between"><span>{{importFileTitle}}</span>
                        <span (click)="cancelOtherImports()" role="button"
                            class="dc-text-secondary fs-6 material-icons ms-5">clear</span></span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list class="dc-bg-light-xs rounded list-max-h">
                <mat-list-item *ngFor="let item of otherFiles">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center">
                            <span class="material-icons fs-5 me-2 dc-text-light-md">article</span>
                            <span class="typo-ps-body-3 dc-text-secondary">{{item.label}}</span>
                        </div>
                        <mat-spinner *ngIf="item.isProcessing" class="ms-3" [diameter]="15"></mat-spinner>
                        <span *ngIf="!item.isProcessing" class="ms-3 dc-text-success fs-4 material-icons">check_circle
                        </span>
                    </div>
                    <mat-progress-bar *ngIf="item.isProcessing"
                        [mode]="item.state == 'PENDING' ? 'buffer' : 'determinate'"
                        [value]="item.progress"></mat-progress-bar>
                </mat-list-item>
            </mat-list>
        </mat-expansion-panel>
    </mat-accordion>
</div>
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { CONSTANT } from '../constants/constant';
// import { AuthService } from '../services/auth.service';

// For logged-in pages - if user not logged in then redirected to the auth pages
export const userGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    // const authService = inject(AuthService);
    const router = inject(Router);
    const token = CONSTANT.getToken();
    const profile = CONSTANT.getProfile();
    if (token && profile?.type) {
        const roleInParam = (route.params && route.params['role'] || '').toLowerCase();
        if (profile?.type?.toLowerCase()! !== roleInParam) {
            const url = state.url.replace(roleInParam, profile?.type?.toLowerCase())
            return router.parseUrl(url)
        }
        return true;
    }
    // Redirect to the login page
    CONSTANT.logout();
    return router.parseUrl('/auth/login');
};
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { CONSTANT} from 'src/app/constants/constant';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class CommonJSService {
    private renderer: Renderer2;
    constructor(rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    stringcheckifnonascii(str: string) {
        var hasMoreThanAscii = /^[\u0000-\u007f]*$/.test(str);
        return hasMoreThanAscii;
    }

    checkValidCharacters(finalpath: string, donotcompare: string) {
        var cancreate = true;
        if (finalpath.length > 0) {
            var ar = finalpath.split("/");
            if (ar.length >= 2 && donotcompare != "/") {
                cancreate = false;
            }

            ar = finalpath.split("\\");
            if (ar.length >= 2) {
                cancreate = false;
            }

            ar = finalpath.split(":");
            if (ar.length >= 2) {
                cancreate = false;
            }

            ar = finalpath.split("*");
            if (ar.length >= 2) {
                cancreate = false;
            }

            ar = finalpath.split("?");
            if (ar.length >= 2) {
                cancreate = false;
            }

            ar = finalpath.split("<");
            if (ar.length >= 2) {
                cancreate = false;
            }

            ar = finalpath.split(">");
            if (ar.length >= 2) {
                cancreate = false;
            }

            ar = finalpath.split("|");
            if (ar.length >= 2) {
                cancreate = false;
            }

            ar = finalpath.split("#");
            if (ar.length >= 2) {
                cancreate = false;
            }
            ar = finalpath.split('"');
            if (ar.length >= 2) {
                cancreate = false;
            }
        } else {
            cancreate = false;
        }

        /*if (cancreate && !this.stringcheckifnonascii(finalpath)) {
            cancreate = false;
        }*/

        return cancreate;
    }

    findInValidCharacters(finalpath: string, donotcompare: string) {
        let chr:string = "";
        if (finalpath.length > 0) {
            if(donotcompare!="/" && finalpath.split("/").length>= 2) chr = "/";
            else if(donotcompare!="/" && finalpath.split("/").length>= 2) chr = "/";
            else if(donotcompare!="\\" && finalpath.split("\\").length>= 2) chr = "\\";
            else if(donotcompare!=":" && finalpath.split(":").length>= 2) chr = ":";
            else if(donotcompare!="*" && finalpath.split("*").length>= 2) chr = "*";
            else if(donotcompare!="?" && finalpath.split("?").length>= 2) chr = "?";
            else if(donotcompare!="<" && finalpath.split("<").length>= 2) chr = "<";
            else if(donotcompare!=">" && finalpath.split(">").length>= 2) chr = ">";
            else if(donotcompare!="|" && finalpath.split("|").length>= 2) chr = "|";
            else if(donotcompare!="#" && finalpath.split("#").length>= 2) chr = "#";
            else if(donotcompare!='#' && finalpath.split('"').length>= 2) chr = '"';
        }
        return chr
    }

    findFolderFileNameWithNonAllowedChr(finalpath: string){
        let name:string = finalpath;
        if(finalpath.indexOf("/") >= 0){
            let chr:string = this.findInValidCharacters(finalpath, "/");
            let arr = finalpath.split("/");
            for(let str in arr){
                if(arr[str].indexOf(chr)>=0){
                    name = arr[str];
                    break;
                }
            }
        }
        return name;
    }

    onWindowResize() {
        const mainToolbar_1_height = (document.getElementById('main-toolbar-1')?.offsetHeight || 64),
            mainToolbar_3_height = (document.getElementById('main-toolbar-3')?.offsetHeight || 68),
            profileNavBar = document.getElementById('main-toolbar-2'),
            mainRouterBody = document.getElementById('main-router-body'),
            mainRouterBodyOuter = document.getElementById('main-router-body-outer'),
            sidebar = document.getElementById('sidebar');
        if (profileNavBar) {
            this.renderer.setStyle(profileNavBar, 'height', `calc(10vh - ${mainToolbar_1_height}px)`, 1)
        }
        if (mainRouterBodyOuter) {
            //   this.renderer.setStyle(mainRouterBodyOuter, 'height', `calc(100vh - ${mainToolbar_1_height}px)`, 1)
        }

        if (mainRouterBody) {
            this.renderer.setStyle(mainRouterBody, 'height', `calc(100vh - ${mainToolbar_1_height + mainToolbar_3_height}px)`, 1);
        }

        if (sidebar) {
            this.renderer.setStyle(sidebar, 'height', `calc(100vh - ${mainToolbar_1_height}px)`, 1);
        }
    }

    onWindowResizeTargetElement(elementId: string) {
        const mainToolbar_1_height = (document.getElementById('main-toolbar-1')?.offsetHeight || 64),
            mainToolbar_3_height = (document.getElementById('main-toolbar-3')?.offsetHeight || 68),
            element = document.getElementById(elementId);
        if (element) {
            this.renderer.setStyle(element, 'height', `calc(100vh - ${mainToolbar_1_height + mainToolbar_3_height+10}px)`, 1);
        }
    }
    refineURL(){
        //get full URL
        var currURL= window.location.href; //get current address
      
        //Get the URL between what's after '/' and befor '?'
        //1- get URL after'/'
        var afterDomain= currURL.substring(currURL.lastIndexOf('/') + 1);
        //2- get the part before '?'
        var beforeQueryString= afterDomain.split("?")[0];
      
        return beforeQueryString;
      }
      
      /**
      method to clean URL after data population
      */
      cleanURLAtTop(){
        var myNewURL = this.refineURL();
        //here you pass the new URL extension you want to appear after the domains '/'. Note that the previous identifiers or "query string" will be replaced.
        window.history.pushState("object or string", "Title", `/web/${CONSTANT.getProfile().type.toLowerCase()}/` + myNewURL );
      }
    
      /**
     * method to get date in TZ format from timestamp
     * @param timestamp
     * @returns {String}
     */
    getDateFromTimestamp(timestamp: number) {
        if ((timestamp + "").length == 10) timestamp = timestamp * 1000;
        if ((timestamp + "").length < 13) return "";
        var a = new Date(timestamp);
        //var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = a.getMonth() + 1;
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        //var sec = a.getSeconds();
        var time = year + '-' + month + '-' + date + 'T' + hour + ':' + min + ':00Z';
        return time;
    }

    /**
     * method to search text in json array for multiple attributes and matches both sides
     * @param json
     * @param attribute array([0] = attribute#value)
     */
    searchJSONLowerCaseMatchMultiple(json: Array<Object>, attribute: Array<string>) {
        let vm = new Array();
        json.forEach(function (v: any) {
            let found = true;
            for (let j = 0; j <= attribute.length - 1; j++) {
                let attrarr = attribute[j].split("<#HASH;>");
                let attr = attrarr[0];
                let datatype = attrarr[1];
                let searchtext = attrarr[2];
                let va: any = (v[attr] + "").toLowerCase();
                let vs: any = searchtext.toLowerCase();
                if (datatype == "string" && va.indexOf(vs) < 0) {
                    found = false;
                } else if (datatype == "number") {
                    va = parseFloat(va);
                    vs = parseFloat(vs);
                    if (va > vs) found = false;
                }
            }
            if (found) vm.push(v);
        });
        return vm;
    }

    // Observable string sources
    private emitChangeSource = new Subject<any>();
    // Observable string streams
    changeEmitted$ = this.emitChangeSource.asObservable();
    // Service message commands
    emitChange(change: any) {
        this.emitChangeSource.next(change);
    }
}


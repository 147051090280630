import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { CONSTANT } from '../constants/constant';
// import { AuthService } from '../services/auth.service';

// For auth pages - if user logged in then redirected to the dashboard
export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    // const authService = inject(AuthService);
    const router = inject(Router);
    if(CONSTANT.changedPassword){
        console.log("authg step 3");
        CONSTANT.changedPassword = false;
        CONSTANT.logout();
        return router.navigateByUrl("/auth/login");
    }

    CONSTANT.newSession = false;
    const token = CONSTANT.getToken();
    const profile = CONSTANT.getProfile();
    const tokenLogin = CONSTANT.getTokenLogin();

    let qp:any = route.queryParams
    if (tokenLogin && qp?.a == "view" && qp?.b == "file") {
        var url = "/viewer-direct?a="+qp?.a+"&b="+qp?.b+"&c="+qp?.c+"&d="+qp?.d+"&e="+qp?.e+"&ft="+qp?.ft+"&gt="+qp?.gt+"&ht="+qp?.ht;
        router.navigateByUrl(url);
    } else {
        const refreshSession = (route?.queryParams?.['refresh'] || '').toLowerCase() === 'session';
        if (token && profile?.type && !refreshSession) {
            CONSTANT.queryParam = route.queryParams;
            return router.parseUrl(`/${profile?.type?.toLowerCase()}`);
        } 
    }

    if((location.href+"").indexOf("/download/folder/")>=0){
        let vd = (location.href+"").substring((location.href+"").lastIndexOf("/")+1);
        CONSTANT.downloadFolderLink = vd;
    }

    // Redirect to the login page
    return true;
};
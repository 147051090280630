const domain_api = 'https://dcirrus.co.in';
const acms_api = domain_api + '/api.acms';
const acms_server_api =domain_api + '/api.acms.server';
export const environment = {
   production: true,
   name:'qa',
   domain_api,
   acms_api,
   acms_server_api,
   acms_api_v1: acms_api + '/v1',
   acms_api_v1_pb: acms_api + '/v1/public',
   acms_server_api_v1: acms_server_api + '/v1'
};
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { CONSTANT_API } from '../constants/constant-apis';
import { CheckCorporateDomainServiceAfter } from '../models/checkCorporateDomainServiceAfter.model';
import { ResponseAcmModel } from '../models/res-acm.model';
import { User } from '../models/user';
import { Profile } from '../models/profile';
import { CONSTANT, downloadFile } from '../constants/constant';
import { forkJoin, map, switchMap, throwError } from 'rxjs';
import { SettingsService } from '../admin/settings/settings.service';
import { RoleWisePermissions } from '../constants/permissions';
import { DownloadNotifierService } from './download-notifier.service';
import { UserLogin } from '../models/user-login';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private http: HttpClient,
        private downloadNotifierService: DownloadNotifierService,

        private settingsService: SettingsService) {

    }

    getMyIpAddress = () => this.http.get<{ ip: string }>(CONSTANT_API.ipAddressApi);

    captcha = () => this.http.post<ResponseAcmModel<{ img: string }>>(CONSTANT_API.loginFetchCaptchaAfter, {});

    fileListCaptcha = () => this.http.post<ResponseAcmModel<{ img: string }>>(CONSTANT_API.fileListCheckCaptcha, {});
    

    profile = (userId: number, corporateId: number) => this.http.get<ResponseAcmModel<Profile>>(CONSTANT_API.profile(userId, corporateId));

    fileVerifyCaptcha = (payload: any) =>
        this.http
          .post<ResponseAcmModel<User>>(CONSTANT_API.verifyFileListCaptcha , payload)
          .pipe(
            map((res) => {
              if (res) {
                
                // CONSTANT.setUser(res.objectD);
                // CONSTANT.setToken(res.objectD?.token);
                // CONSTANT.setRememberMe(payload.userValidateDto.rememberMe);
              }
              return res;
            })
          );

    loginsuccess = (payload: any) =>
        this.http
          .post<ResponseAcmModel<User>>(CONSTANT_API.loginsuccess, payload)
          .pipe(
            map((res) => {
              if (res.objectD) {
                CONSTANT.setUser(res.objectD);
                CONSTANT.setToken(res.objectD?.token);
                CONSTANT.setRememberMe(payload.userValidateDto.rememberMe);
              }
              return res;
            })
          );
    

    closuresendotp = (payload: Object) => this.http.post<ResponseAcmModel<User>>(CONSTANT_API.closuresendotp, payload);

    closuredownloaddata = (payload: Object) => this.http.post<ResponseAcmModel<User>>(CONSTANT_API.closuredownloaddata, payload);

    verifierDownloadData() {
        const httpPost = this.http.post(
            CONSTANT_API.verifierDownloadData,
            {},
            {
                reportProgress: true,
                observe: 'events',
                responseType: 'blob'
            }).pipe(map(event => {

                if (event.type === HttpEventType.Response) {
                    downloadFile(event, "labour-law-india.pdf");
                }
                return event;
            }));

        this.downloadNotifierService.downloadingItems.emit({
            downloadObservable: httpPost,
            label: 'Downloading files',
            isProcessing: true,
            progress: 0,
            state: 'PENDING',
            errormsg: ''
        });
        return httpPost;
    }

    refreshToken() {
        const deviceId = CONSTANT.getUser().emailId.toUpperCase().replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        const payload = {
            "userValidateDto": {
                "userName": "",
                "deviceId": deviceId
            },
            "deviceDto": {
                "token": CONSTANT.getUser().tempAttr9,
                "regNew": "browser",
                "deviceType": "desktop",
                "deviceName": "Web Browser",
                "deviceId": deviceId,
                "tempAttr1": CONSTANT.getUser().lawFirmId,
                "tempAttr2": CONSTANT.getUser().userId
            }
        }
        return this.loginsuccess(payload).pipe(switchMap(res => {
            const { objectD } = res;
            if (objectD) {
                CONSTANT.setUser(objectD);
                CONSTANT.setToken(objectD?.token);
                CONSTANT.setRememberMe(1);
                if (objectD?.lawFirmId == 0 || (objectD?.disclaimerTitle && objectD.disclaimerMessage)) {
                    return throwError(() => ({ refresh: 'session' }));
                }
            }
            return this.refreshProfile();
        }));
    }

    refreshProfile() {
        const user = CONSTANT.getUser();
        return forkJoin({
            profile: this.profile(user?.userId, user?.lawFirmId),
            storage: this.fetchstoragespace(),
            settings: this.settingsService.adminfetchCorporateDetailsServiceAfter(user?.lawFirmId)
        }).pipe(map(({ profile, storage, settings }) => {
            if (profile?.object?.type) {
                CONSTANT.setProfile(profile?.object);
                RoleWisePermissions.configPermissions();
            }
            if (storage?.object) {
                CONSTANT.setStorage(storage?.object);
            }
            return { profile, storage, settings }
        }))
    }

    logout = () => this.http.post<any>(CONSTANT_API.logout, {});

    checkCorporateDomainServiceAfter = (corporateId: string | number) => this.http.get<CheckCorporateDomainServiceAfter>(CONSTANT_API.checkCorporateDomainServiceAfter(corporateId));

    forgotPassword = (email: string) => this.http.post<CheckCorporateDomainServiceAfter>(CONSTANT_API.forgotpass, { "attribute1": "forgotpassServiceAfter", "attribute2": email, "attribute4": "angular" });

    resetPassword = (payload: any) => this.http.post<CheckCorporateDomainServiceAfter>(CONSTANT_API.resetPassword, payload);

    resetPasswordStatus = (payload: any) => this.http.post<any>(CONSTANT_API.resetPasswordStatus, payload);

    fetchstoragespace = () => this.http.post<any>(CONSTANT_API.fetchstoragespace, { "attribute1": "1" });

    disclaimer = (id: 1 | 2) => this.http.post<any>(CONSTANT_API.disclaimer, { "id": `${id}`, "action": "disclaimerAcceptorDeclaineServiceAfter" });

    dcirrusDisclaimer = (id: 1 | 2) => this.http.post<any>(CONSTANT_API.dcirrusDisclaimer, { "id": `${id}`, "action": "updateDcirrusDisclaimerServiceAfter" });

    loginsendotpserviceAfter = (loginId: string, corporateNumber: string, emailAuth?: boolean) => this.http.post<any>(CONSTANT_API.loginsendotpserviceAfter, {
        "attribute1": loginId,
        "attribute3": corporateNumber,
        "attribute4": emailAuth ? 1 : 0
    });

    validateOtp = (otp: string) => {
        const headers = new HttpHeaders({ Auhotp: otp })
        return this.http.post<any>(CONSTANT_API.otpCheck, {}, { headers }).pipe(map(res =>{
            if(res.objectD || res.object){
                const data = res.objectD || res.object;
                CONSTANT.setUser(data);
                CONSTANT.setToken(data?.token);
            }
            return res
        }))
    };

    changepassStatusServiceAfter = () => this.http.post<any>(CONSTANT_API.changepassStatusServiceAfter, { "attribute1": "changepassStatusServiceAfter" });
    admChangeNotifStatusServiceAfter = () => this.http.post<any>(CONSTANT_API.admChangeNotifStatusServiceAfter, { "attribute1": "admChangeNotifStatusServiceAfter" });

    loginsuccessUser = (payload: Object) => this.http.post<ResponseAcmModel<UserLogin>>(CONSTANT_API.loginsuccessuser, payload);
    dcirrusDisclaimerLogin = (payload: Object) => this.http.post<ResponseAcmModel<any>>(CONSTANT_API.updateDCirrusDisclaimerLogin, payload);

    resetPasswordLogin = (payload: any) => this.http.post<CheckCorporateDomainServiceAfter>(CONSTANT_API.resetPasswordLogin, payload);

    fetchUserLoginStatus = (payload: any) => this.http.post<any>(CONSTANT_API.fetchUserLoginStatus, payload);
    activateUserStatus = (payload: any) => this.http.post<any>(CONSTANT_API.activateUserStatus, payload);
    activateUserLoginStatus = (payload: any) => this.http.post<any>(CONSTANT_API.activateUserLoginStatus, payload);
}

import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadItem, DownloadNotifierService } from 'src/app/services/download-notifier.service';
import { HttpClientModule, HttpEventType } from '@angular/common/http';
import { SnackService } from 'src/app/services/snack.service';
import { MsgPopupService } from '../../shared/msg-popup/msg-popup.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MsgPopupModule } from '../../shared/msg-popup/msg-popup.module';

@Component({
  selector: 'dcrs-download-items',
  standalone: true,
  imports: [CommonModule, HttpClientModule,
    MatListModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MsgPopupModule,],
  templateUrl: './download-items.component.html',
  styleUrls: ['./download-items.component.scss']
})
export class DownloadItemsComponent {

  downloadTitle = 'Downloading...';
  items: Array<DownloadItem> = [];


  constructor(downloadNotifierService: DownloadNotifierService,
    private snackService: SnackService,
    private msgPopupService: MsgPopupService
  ) {
    downloadNotifierService.downloadingItems.subscribe(item => {
      this.items.push(item);
      this.downloadTitle = this.items.every(i => !i.isProcessing) ? 'Download Completed' : 'Downloading...';
      item['subscription'] = item.downloadObservable.subscribe({next: (event: any) => {
        switch (event.type) {
          case HttpEventType.DownloadProgress:
            item.state = 'IN_PROGRESS';
            item.progress = (event.loaded * 100) / (event.total || event.loaded);
            break;
          case HttpEventType.Response:
            item.state = 'DONE';
            item.progress = 100;
            item.isProcessing = false;
            const allDownloaded = this.items.every(i => !i.isProcessing);
            this.downloadTitle = allDownloaded ? 'Download Completed' : 'Downloading...';
            if (allDownloaded) {
              this.snackService.success(`${this.items.length} item(s) downloaded successfully`)
              const st = setTimeout(() => {
                this.items.forEach(i => i.subscription?.unsubscribe());
                const _st = setTimeout(() => {
                  this.items = [];
                  clearTimeout(st);
                  clearTimeout(_st);
                }, 100);
              }, 500);
            }
            break;
        }
      },
      error: (err: any) => {
        this.snackService.error(item.errormsg.length? item.errormsg: 'Could not process the request.Please try again')
        const st = setTimeout(() => {
          this.items.forEach(i => i.subscription?.unsubscribe());
          const _st = setTimeout(() => {
            this.items = [];
            clearTimeout(st);
            clearTimeout(_st);
          }, 100);
        }, 500);
      }});
    })
  }

  cancelDownload() {
    if (this.items.some(i => i.isProcessing)) {
      this.msgPopupService.openMsgPopup({
        theme: 'default',
        confirmation: true,
        closeable: false,
        messages: {
          title: 'Cancel Download',
          subTitle: 'Are you sure you want to cancel download?'
        },
        btns: { close: { title: 'Continue Download' }, continue: { title: 'Cancel Download' } }
      }).afterClosed().subscribe(val => {
        if (val) {
          this.items.forEach(i => i.subscription?.unsubscribe());
          setTimeout(() => {
            this.items = [];
          }, 100);
        }
      })
    } else {
      this.items = [];
    }
  }

}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewFolderComponent } from 'src/app/modules/new-folder/components/new-folder/new-folder.component';
import { CONSTANT } from 'src/app/constants/constant';
import { Router } from '@angular/router';
import { SnackService } from 'src/app/services/snack.service';

export interface MsgPopupTheme {
  title: string
  cancle: string
  continue: string
}

@Component({
  selector: 'dcrs-msg-popup',
  templateUrl: './msg-popup.component.html',
  styleUrls: ['./msg-popup.component.scss']
})
export class MsgPopupComponent {
  showTimer:boolean = false;

  buttonConfig: { [key: string]: MsgPopupTheme } = {
    default: {
      title: 'dc-text-primary',
      cancle: 'border border-width-2 mx-3 px-4 dc-border-primary dc-text-primary',
      continue: 'px-4 dc-bg-primary dc-text-light'
    },
    warn: {
      title: 'dc-text-warn',
      cancle: 'border border-width-2 mx-3 px-4 dc-border-warn dc-text-warn',
      continue: 'px-4 dc-bg-warn dc-text-light'
    },
    danger: {
      title: 'dc-text-danger',
      cancle: 'border border-width-2 mx-3 px-4 dc-border-danger dc-text-danger',
      continue: 'px-4 dc-bg-danger dc-text-light'
    }
  }

  theme: MsgPopupTheme = this.buttonConfig['default'];
  align: "center" | "start" | "end" = "start";

  constructor(
    private router: Router,
    private snackService: SnackService,
    public dialogRef: MatDialogRef<NewFolderComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: {
      messages?: { title?: string, subTitle?: string, subTitles?: string[] },
      closeable?: boolean,
      btns?: { close: { title: string }, continue: { title: string } },
      confirmation?: boolean,
      theme: 'default' | 'warn' | 'danger',
      align: "center" | "start" | "end"
    }) {
    this.align = data?.align || 'start';
    if (data?.theme && this.buttonConfig[data?.theme?.toLowerCase()]) {
      this.theme = this.buttonConfig[data?.theme?.toLowerCase()];
    }

    if(data?.messages?.subTitle=="Due to inactivity the session is going to close in one more minute. Do you wish to remain logged in?"){
      this.showTimer = true;

      clearInterval(CONSTANT.ytimer);
      
      var timtoadd = CONSTANT.checkLogoutAfterMin*60*1000;
      localStorage.setItem("countDownDate", (new Date().getTime() + timtoadd)+"");
      CONSTANT.xtimer = setInterval(function() {
        // Get today's date and time
        if(parseFloat(localStorage.getItem("countDownDate")+"") >= -1){
            var now = new Date().getTime();
            var distance = new Date(parseFloat(localStorage.getItem("countDownDate")+"")).getTime() - now;

            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            CONSTANT.sessiontimer = minutes + ":" + seconds;
            if (minutes <= 0 && seconds <= 0) {
                localStorage.setItem("timerpopup", "false");
                CONSTANT.logout();
                snackService.error('Session Expired!')
                router.navigateByUrl('/auth/login');
            } 
        } else {
            window.location.reload();
        }
      }, 1000);
    }
  }

  inactivitysessionchecktimer(){
    
  }
}

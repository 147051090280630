import { HttpClient,  HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, mergeMap, of } from 'rxjs';
import { CONSTANT } from 'src/app/constants/constant';
import { CONSTANT_API } from 'src/app/constants/constant-apis';
import { environment } from 'src/environments/environment';
import { DownloadNotifierService } from 'src/app/services/download-notifier.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient, private downloadNotifierService: DownloadNotifierService) { }


  adminfetchCorporateDetailsServiceAfter(lawfirm: number) {
    return this.http.get<any>(CONSTANT_API.adminfetchCorporateDetailsServiceAfter(lawfirm)).pipe(map(res => {
      if (res?.object) {
        CONSTANT.setSettings(res?.object);
      }
      return res;
    }))
  }

  fetchRegionListData() {
    return this.http.get<any>(CONSTANT_API.fetchRegionListData)
  }

  disclaimerFetch() {
    return this.http.post<any>(CONSTANT_API.disclaimerFetch, { "action": "fetchDisclaimerDetailsAfter" })
  }

  metatdataFetch() {
    return this.http.post<any>(CONSTANT_API.metatdataFetch, { "attribute1": "fetchMetadataMasterServiceAfter" })
  }

  updateSettings(payload: any) {
    return this.http.post(CONSTANT_API.updateSettings, { ...payload, "action": "adminSaveClientServiceAfter" })
  }

  updateDisclaimer(payload: any) {
    delete payload?.msg;
    return this.http.post(CONSTANT_API.updateDisclaimer, { ...payload, action: "updateDisclaimerDetailsAfter" })
  }

  addDisclaimer(payload: any) {
    return this.http.post(CONSTANT_API.addDisclaimer, { ...payload, action: "addDisclaimerDetailsServiceAfter" })
  }

  metatdataDelete(id: any) {
    return this.http.post(CONSTANT_API.metatdataDelete, { "attribute1": "deleteMetadataMasterServiceAfter", "attribute2": id })
  }

  metatdataAdd(metatdata: string) {
    return this.http.post<any>(CONSTANT_API.metatdataAdd, { "attribute1": "addMetadataMasterServiceAfter", "attribute2": metatdata })
  }

  disclaimerAlltypes() {
    return this.http.post<any>(CONSTANT_API.disclaimerAlltypes, { "action": "fetchDisclaimerTypesAfter" })
  }


  fileToBase64(file?: File | null) {
    return new Observable(observer => {
      if (file) {
        // data:image/png;base64,
        const reader = new FileReader();
        reader.onloadend = () => {
          observer.next(reader.result);
        };
        reader.readAsDataURL(file);
      } else { observer.next(''); }
    })
  }

  updatelogo(file?: File | null, fileBase64?: any) {
    if (fileBase64) {
      return this.http.post(CONSTANT_API.updatelogo, { "attribute1": "adminUpdateLogoServiceAfter", attribute2: fileBase64 });
    }
    return this.fileToBase64(file).pipe(mergeMap(attribute2 => {
      return this.http.post(CONSTANT_API.updatelogo, { "attribute1": "adminUpdateLogoServiceAfter", attribute2 });
    }))

  }

  //IP Range Starts
  fetchIpRangeList() {
    return this.http.post<any>(CONSTANT_API.fetchIpRangeList, {
      "action": "fetchIpManageServiceAfter"
    });
  }

  addIpRange(form: any) {
    return this.http.post<any>(CONSTANT_API.addIpRange, {
      "action": "addIpManageServiceAfter",
      "ipAddressStart": form.ipFrom,
      "ipAddressEnd": form.ipTo,
      "status": "A"
    });
  }

  importIpRange(file: File) {
    const fd = new FormData();
    fd.append('file', file, file.name);
    const headers = new HttpHeaders()
      .set('webSource', 'angular');
    const uploadObservable = this.http
      .post(CONSTANT_API.importIpRange, fd, {
        headers,
        reportProgress: true,
        observe: 'events',
        responseType: 'blob'
      })
      .pipe(
        map(event => {
          if (event.type == HttpEventType.Response) {
            const user = CONSTANT.getUser();
            const dateObj = new Date();
            const month = dateObj.getUTCMonth() + 1; //months from 1-12
            const day = dateObj.getUTCDate();
            const year = dateObj.getUTCFullYear();
            const hour = dateObj.getHours();
            const minute = dateObj.getMinutes();
            let filename = 'Import_IP' +
              '_' +
              user.lawFirmId +
              '_' +
              year +
              '_' +
              month +
              '_' +
              day +
              '_' +
              hour +
              '_' +
              minute +
              '.csv';
            let encodedUri = window.URL.createObjectURL(
              new Blob([event?.body || ''])
            );
            let link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', filename);
            document.body.appendChild(link); // Required for FF
            link.click(); // This will download the data file named "my_data.csv".
            document.body.removeChild(link);
          }
          return event;
        })
      );
    this.downloadNotifierService.importOtherFiles.emit({
      uploadObservable,
      isProcessing: true,
      progress: 0,
      state: 'PENDING',
      label: file.name,
    });
  }

  deleteIpRange(ids: string){
    return this.http.post<any>(CONSTANT_API.deleteIpRange, {
      "attribute1": "deleteIpManageServiceAfter",
      "attribute2": ids
    });
  }

}

const disclaimer = { "id": 1, "type": "2", "title": "Conditions of dAccess", "status": 1, "action": "updateDisclaimerDetailsAfter" }

const updateSettings = {
  "id": "1180",
  "clientName": "Dcirrus New GUI",
  "startDate": "2023-03-03T7:48:33Z",
  "lawFirmDetails": [
    {
      "id": "2",
      "firstName": "Pritish",
      "middleName": "",
      "lastName": "Padhan",
      "emailId": "pritish@dcirrus.com",
      "phone": "",
      "faxNumberExt": 0,
      "phoneNumberExt": 0
    }
  ],
  "lawFirmAddress": [
    {
      "id": "2",
      "street": "",
      "city": "",
      "state": "",
      "place": "",
      "country": "",
      "zipcode": ""
    }
  ],
  "serverUrl": environment.domain_api,
  "serviceServerUrl": environment.domain_api,
  "s3RegionId": "2",
  "watermarkText": "Dcirrus New GUI [useremail] [date] [ipaddress]",
  "watermarkDisplay": "1,1,0",
  "watermarkColor": "8",
  "showProjectIndexNo": 1,
  "drmAutoRemoteWipe": 0,
  "docViewTimeSpan": "10",
  "currency": "INR",
  "timeZone": "Indian Standard Time,GMT+05:30",
  "watermarkDisplayType": "2,0,1",
  "action": "adminSaveClientServiceAfter",
  "twoFactorAuth": 0,
  "rootAccesstoAllAdmins": 1
}

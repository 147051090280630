import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackService {

  constructor(private matSnackBar: MatSnackBar) {
   }

  success = (text: string, action?: string, config?: MatSnackBarConfig) => this.open(text, 'snackbar_success', action, config);

  warn = (text: string, action?: string, config?: MatSnackBarConfig) => this.open(text, 'snackbar_warn', action, config);

  info = (text: string, action?: string, config?: MatSnackBarConfig) => this.open(text, 'snackbar_info', action, config);

  error = (text: string, action?: string, config?: MatSnackBarConfig) => this.open(text, 'snackbar_error', action, config);

  private open(text: string, panelClass: string, action?: string, config?: MatSnackBarConfig,) {
    return this.matSnackBar.open(text, action || 'X', config || { panelClass })
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DynamicFlatNode } from '../modules/standalone/folder-tree/dynamic-flat-node';
import { FileOrFolder } from '../models/file-folder.model';

@Injectable({
    providedIn: 'root'
})
export class MissionService {
    // Observable string sources
    private folderSelectAnnouncedSource = new Subject<{ node: DynamicFlatNode | null, type: 'navigate' | 'export-index' }>();
    private folderUpdateOnTreeAnnouncedSource = new Subject<{ folder?: FileOrFolder | null, refresh?: boolean }>();
    private dataRoomAnnouncedSource = new Subject<{ navigateToRoot?: boolean }>();

    // Observable string streams
    folderSelectAnnounced$ = this.folderSelectAnnouncedSource.asObservable();
    folderUpdateOnTreeAnnounced$ = this.folderUpdateOnTreeAnnouncedSource.asObservable();
    dataRoomAnnounced$ = this.dataRoomAnnouncedSource.asObservable();

    // Service message commands
    folderSelectMission(mission: { node: DynamicFlatNode | null, type: 'navigate' | 'export-index' }) {
        this.folderSelectAnnouncedSource.next(mission);
    }

    folderUpdateOnTreeMission(mission: { folder?: FileOrFolder | null, refresh?: boolean }) {
        this.folderUpdateOnTreeAnnouncedSource.next(mission);
    }

    dataRoomMission(mission: { navigateToRoot?: boolean }) {
        this.dataRoomAnnouncedSource.next(mission);
    }
}
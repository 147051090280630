import { HttpResponse } from "@angular/common/http";
import { User } from "../models/user";
import { Profile } from "../models/profile";
import { UserPermissionsModel } from "../models/user-permission.model";
import { AllowedAction, FileOrFolder } from "../models/file-folder.model";
import { ActivatedRouteSnapshot } from "@angular/router";
import * as moment from "moment";
import { UserLogin } from "../models/user-login";
import { UserLoginDto } from "../models/userlogindto";

const userKey = 'Wlc1eVptedynG1abXR6Wmc9PQ==';
const profileKey = 'aksfjtdv==';
const tokenKey = 'Wlc1eVsfpteG1abXR6Wmc9PQ==';
const storageSpaceKey = 'Wlc1eVszskjgskjcfpteG1abXR6Wmc9PQ==';
const previewthemeKey = 'Wlc1eVzmbaksvQ==';
const themeKey = 'Wlc1eVdhhhddzmbaksvQ==';
const settingsKey = 'lfkb8yt==';
const viewTypeKey = 'lfkdffb8yt==';
const permissionKey = 'skdfjsofiihlsk==';
const rememberMe = 'skdihlsk==';

const userKeyLogin = 'Klc1eGptadynG3abVR6Qmc7PM==';
const tokenKeyLogin = 'Klc1eVsraddnG3abVR6Qmc7PM==';
const profileKeyLogin = 'blpgktdv==';

export class CONSTANT {
    static queryParam:any;
    static newSession:boolean = true;
    static changedPassword:boolean = false;
    static enableUpload:boolean = false;
    static selectedGroupType:number = 0;
    static downloadFolderLink:string = "";

    static PASSWORD = {
        REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#\$%\^&\*])(?=.{8,})/,
        ERROR_MSG: "Password between 8 and 20 characters. Password should contain at least one uppercase alphabet, one lowercase alphabet, one digit, and one special character from ~!@$%^&()_-#?.*{}|\':\";=+`"
    };

    static SUCCESS_MSG = {
        PASSWORD_RESET: 'Password changed successfully',
        TO_LOGIN_PAGE: 'You will be redirected to the login page'
    }

    static SUCCESS_MSG_ACC_ACTIVATED = {
        PASSWORD_RESET: 'Account activated',
        TO_LOGIN_PAGE: 'You will be redirected to the login page'
    }

    static setRememberMe(data: number) {
        if (data) {
            localStorage.setItem(rememberMe, window.btoa(window.btoa(JSON.stringify(data))))
        }
    }

    static getRememberMe(): number {
        if (localStorage.getItem(rememberMe)) {
            const rme = JSON.parse(window.atob(window.atob(localStorage.getItem(rememberMe) || '')));
            return rme;
        }
        return 0 as number;
    }

    static setPermissions(data: UserPermissionsModel) {
        if (data) {
            localStorage.setItem(permissionKey, window.btoa(window.btoa(JSON.stringify(data))))
        }
    }

    static getPermissions(): UserPermissionsModel {
        if (localStorage.getItem(permissionKey)) {
            const permissions = JSON.parse(window.atob(window.atob(localStorage.getItem(permissionKey) || '')));
            return permissions;
        }
        return {} as UserPermissionsModel;
    }


    static setTheme(data: object) {
        if (data) {
            localStorage.setItem(themeKey, window.btoa(window.btoa(JSON.stringify(data))))
        }
    }

    static getTheme() {
        if (localStorage.getItem(themeKey)) {
            const theme = JSON.parse(window.atob(window.atob(localStorage.getItem(themeKey) || '')));
            return theme;
        }
        return null;
    }

    static setViewType(viewtype: VIEWER_TYPE) {
        if (viewtype) {
            localStorage.setItem(viewTypeKey, window.btoa(window.btoa(viewtype)))
        }
    }

    static getViewType(): VIEWER_TYPE {
        if (localStorage.getItem(viewTypeKey)) {
            const viewtype = window.atob(window.atob(localStorage.getItem(viewTypeKey) || ''));
            return <VIEWER_TYPE>viewtype;
        }
        return VIEWER_TYPE.ADOBE_VIEWER;
    }



    static setPreviewTheme(data: object) {
        if (data) {
            let themes = this.getPreviewTheme() || [];
            themes.push({ date: (new Date()).toISOString(), selected: false, data });
            localStorage.setItem(previewthemeKey, window.btoa(window.btoa(JSON.stringify(themes))))
        }
    }

    static removePreviewTheme(index: number) {
        if (index >= 0) {
            let themes = this.getPreviewTheme() || [];
            if (themes[index]) {
                themes.splice(index, 0)
            }
            localStorage.setItem(previewthemeKey, window.btoa(window.btoa(JSON.stringify(themes))))
        }
    }

    static updatePreviewTheme(index: number, theme: any) {
        if (index >= 0) {
            let themes = this.getPreviewTheme() || [];
            if (themes[index]) {
                themes[index] = theme;
            }
            localStorage.setItem(previewthemeKey, window.btoa(window.btoa(JSON.stringify(themes))))
        }
    }

    static setPreviewThemeSession(val: string) {
        if (val === 'true') {
            sessionStorage.setItem(previewthemeKey, 'true')
        }
    }
    static getPreviewThemeSession() {
        return sessionStorage.getItem(previewthemeKey)
    }

    static getPreviewTheme() {
        if (localStorage.getItem(previewthemeKey)) {
            const theme = JSON.parse(window.atob(window.atob(localStorage.getItem(previewthemeKey) || '')));
            return theme;
        }
        return null;
    }

    static setUserOnly(data: User) {
        if (data) {
            localStorage.setItem(userKey, window.btoa(window.btoa(JSON.stringify(data))))
        }
    }

    static setUser(data: User) {
        if (data) {
            localStorage.setItem(userKey, window.btoa(window.btoa(JSON.stringify(data))))

            //set old UI loalstorage
            localStorage.setItem("_zz", data.tempAttr9); 
            localStorage.setItem("_zx", data.token);
            localStorage.setItem("_zy", data.emailId);
            localStorage.setItem("_zw", data.lawFirmId+"");
            localStorage.setItem("_zu", data.tempAttr2);
            localStorage.setItem("_zk", data.name);
            localStorage.setItem("_zv", data.userId+"");
            localStorage.setItem("_zq", data.id+"");
            localStorage.setItem("_zp", "0");
            localStorage.setItem("_zr", data.tempAttr5);
            localStorage.setItem("_zh", data.forceChangePass+"");
            localStorage.setItem("_za", data.forceChangeNotif+"");
    
            localStorage.setItem("_zo", data.tempAttr4);
            localStorage.setItem("_zs", data.accType);
            localStorage.setItem("_zn", data.userRole);
            localStorage.setItem("_zzs", data.storageType);
            localStorage.setItem("_zzq", data.tempAttr11);
            localStorage.setItem("_zzp", data.screenPref);
            localStorage.setItem("_zzgi", data.groupId+"");
            localStorage.setItem("_zzgt", data.groupType);
            localStorage.setItem("_zzgn", data.groupName);
            localStorage.setItem("_zzga", data.tempAttr8);
            data.displayType = data.displayType?data.displayType:'table'; 
            localStorage.setItem("dataroomview", data.displayType);

            //logo path
            const random = Math.random() * 100000;
            var logo = data.tempAttr10+"";
            logo = (logo != "null" && logo != "" ? logo+"?v="+random : "");
            localStorage.setItem("_zl", logo);

            var usermodes = data.userModuleAcls;
		    if(usermodes && usermodes.includes(8)){
			    localStorage.setItem("_zp", "1");
		    }

            if(data.groupType=="ADMIN" || data.groupType=="FULL ADMIN"){
                localStorage.setItem("_zp", "1");
            }

            localStorage.setItem("_zmd", data.corpModules.join());
        }
    }

    static getUser(): User {
        if (localStorage.getItem(userKey)) {
            const user = JSON.parse(window.atob(window.atob(localStorage.getItem(userKey) || '')));
            return user;
        }
        return {} as User;
    }

    static setUserLogin(data: UserLogin) {
        localStorage.setItem(userKeyLogin, window.btoa(window.btoa(JSON.stringify(data))))
    }

    static getUserLogin(): UserLogin {
        if (localStorage.getItem(userKeyLogin)) {
            const user = JSON.parse(window.atob(window.atob(localStorage.getItem(userKeyLogin) || '')));
            return user;
        }
        return {} as UserLogin;
    }

    static setSettings(data: object) {
        if (data) {
            localStorage.setItem(settingsKey, window.btoa(window.btoa(JSON.stringify(data))))
        }
    }

    static getSettings() {
        if (localStorage.getItem(settingsKey)) {
            const settings = JSON.parse(window.atob(window.atob(localStorage.getItem(settingsKey) || '')));
            return settings;
        }
        return null;
    }

    static setStorage(data: any) {
        if (data) {
            var attr1 = data.attribute1;
            var attr2 = data.attribute2;
            var used = 0;
            var personal = parseFloat(attr1.split("#")[1]);
            var dataroom = parseFloat(attr1.split("#")[2]);
            var allocated = parseFloat(attr2.split("#")[0]);
            used = parseFloat(attr2.split("#")[1]);
            if (used > allocated) {
                used = allocated;
            }

            var sizeLeft = allocated - used;
            var perntageused = Math.floor(used * 100 / allocated);
            var perntagePerosnal = Math.floor(personal * 100 / allocated);
            var perntageDataroom = Math.floor(dataroom * 100 / allocated);
            var perntageleft = 100 - perntageused;
            localStorage.setItem(storageSpaceKey, window.btoa(window.btoa(JSON.stringify({
                personal, dataroom, used, allocated, perntageused, perntagePerosnal, perntageDataroom
            }))))
        }
    }

    static getStorage() {
        if (localStorage.getItem(storageSpaceKey)) {
            const storage = JSON.parse(window.atob(window.atob(localStorage.getItem(storageSpaceKey) || '')));
            return storage;
        }
        return null;
    }

    static setProfile(data: Profile) {
        if (data) {
            localStorage.setItem(profileKey, window.btoa(window.btoa(JSON.stringify(data))))
        }
    }

    static getProfile(): Profile {
        if (localStorage.getItem(profileKey)) {
            const profile = JSON.parse(window.atob(window.atob(localStorage.getItem(profileKey) || '')));
            return profile;
        }
        return {} as Profile;
    }

    static getUserProfile(): { user: User, profile: Profile } {
        return {
            user: CONSTANT.getUser(),
            profile: CONSTANT.getProfile()
        }
    }

    static setProfileLogin(data: UserLoginDto) {
        if (data) {
            localStorage.setItem(profileKeyLogin, window.btoa(window.btoa(JSON.stringify(data))))
        }
    }

    static getProfileLogin(): UserLoginDto {
        if (localStorage.getItem(profileKeyLogin)) {
            const profile = JSON.parse(window.atob(window.atob(localStorage.getItem(profileKeyLogin) || '')));
            return profile;
        }
        return {} as UserLoginDto;
    }

    static getUserProfileLogin(): { user: UserLogin, profile: UserLoginDto } {
        return {
            user: CONSTANT.getUserLogin(),
            profile: CONSTANT.getProfileLogin()
        }
    }

    static logout() {
        this.logoutDataroom();
        CONSTANT.clearUserLogin();
        CONSTANT.clearTokenLogin();
        localStorage.clear();
    }

    static logoutDataroom() {
        CONSTANT.clearProfile();
        CONSTANT.clearUser();
        CONSTANT.clearToken();
        CONSTANT.clearSettings();
    }

    static setToken(token: string) {
        if (token) {
            localStorage.setItem(tokenKey, window.btoa(window.btoa(token)))
        }
    }
    static getToken() {
        if (localStorage.getItem(tokenKey)) {
            const token = window.atob(window.atob(localStorage.getItem(tokenKey) || ''));
            return token;
        }
        return null;
    }

    static setTokenLogin(token: string) {
        if (token) {
            localStorage.setItem(tokenKeyLogin, window.btoa(window.btoa(token)))
            localStorage.setItem("_zxl", token);
        }
    }

    static getTokenLogin() {
        if (localStorage.getItem(tokenKeyLogin)) {
            const token = window.atob(window.atob(localStorage.getItem(tokenKeyLogin) || ''));
            return token;
        }
        return null;
    }

    static clearProfile = () => localStorage.removeItem(profileKey);
    static clearUser = () => localStorage.removeItem(userKey);
    static clearToken = () => localStorage.removeItem(tokenKey);
    static clearPreviewTheme = () => localStorage.removeItem(previewthemeKey);
    static clearSettings = () => localStorage.removeItem(settingsKey);

    static clearUserLogin = () => localStorage.removeItem(userKeyLogin);
    static clearTokenLogin = () => localStorage.removeItem(tokenKeyLogin);

    //screen time out code starts
    static baseCompTimer1:any;
    static baseCompTimer2:any;
    static corporateCompTimer1:any;
    static corporateCompTimer2:any;
    static logoutAfterMin:any;
    static checkLogoutAfterMin:any;
    static intervalcheck:any;
    static ytimer:any;
    static timelast:any;
    static pointerX:any;
    static pointerY:any;
    static xtimer:any;
    static ztimer:any;
    static ttimer:any;
    static screentimeout:boolean;
    static sessiontimer:any;

    static clearInterval45MinuteTimers(){
        clearInterval(CONSTANT.baseCompTimer1);
        clearInterval(CONSTANT.baseCompTimer2);
        clearInterval(CONSTANT.baseCompTimer1);
        clearInterval(CONSTANT.baseCompTimer2);
    }

    static initScreenTimeOut(){
        CONSTANT.logoutAfterMin = 45;
        CONSTANT.checkLogoutAfterMin = 5;
        CONSTANT.intervalcheck = 300000;
        CONSTANT.timelast = 0;
        CONSTANT.pointerX = -1;
        CONSTANT.pointerY = -1;
        CONSTANT.xtimer = null;
        CONSTANT.ztimer = null;
        CONSTANT.ttimer = null;
        CONSTANT.sessiontimer = "";
        CONSTANT.screentimeout = false;
        document.onmousemove = function(event) {
            if(CONSTANT.screentimeout==false){
                CONSTANT.pointerX = event.pageX;
                CONSTANT.pointerY = event.pageY;
                localStorage.setItem("timelast", new Date().getTime()+"");
                CONSTANT.screentimeout = false;
            }
        }
        CONSTANT.ytimer = setInterval(CONSTANT.pointerCheck, CONSTANT.intervalcheck);
    }

    static pointerCheck() {
        if(CONSTANT.getTokenLogin()!=null || CONSTANT.getToken()!=null){
            if(localStorage.getItem("timelast") == "0") localStorage.setItem("timelast", new Date().getTime()+"");
                var timediff = new Date().getTime()-parseFloat(localStorage.getItem("timelast")+"");
                var diffMins = Math.round(((timediff % 86400000) % 3600000) / 60000);

            if(diffMins >= (CONSTANT.logoutAfterMin-CONSTANT.checkLogoutAfterMin) && !CONSTANT.screentimeout){
                CONSTANT.screentimeout = true;
            }
        }
    }

    static resetxtimer(){
        CONSTANT.screentimeout = false;
        clearInterval(CONSTANT.xtimer);
        CONSTANT.ytimer = setInterval(CONSTANT.pointerCheck, CONSTANT.intervalcheck);
    }

    static sessionactive(){
        localStorage.setItem("timelast", new Date().getTime()+"");
        CONSTANT.resetxtimer();
    }
    //screen time out code ends

    static parentFolderStatus:string = "A";
}

export const redirect_urls = {
    "prevecyPolicyUrl": "https://www.dcirrus.com/privacy-policy/",
    "termsServicesUrl": "https://www.dcirrus.com/terms-of-services/",
    "contactUsUrl": "https://www.dcirrus.com/contact/",
    "dcirrusWebUrl": "https://dcirrus.com",
    "helpUrl": "https://www.dcirrus.com/help/"
}

export enum FolderType {
    DATA_ROOM = 'S',
    PERSONAL_FILES = 'P'
}

export enum VIEWER_TYPE {
    NORMAL_VIEWER = 'NORMAL_VIEWER',
    ADOBE_VIEWER = 'ADOBE_VIEWER'
}

export enum GroupType {
    ALL = 0,
    PARTNER = 1,
    ADMIN = 2,
    USER = 3,
    UNMANAGED
}

export enum GroupTypeName {
    PARTNER = "Full Admin",
    ADMIN = "Admin",
    USER = "User",
    UNMANAGED = "Unmanaged"
}

export enum UserTypeName {
    PARTNER = "FULL ADMIN",
    ADMIN = "ADMIN",
    USER = "USER",
}

export enum TwoFactAuthOption {
    MOBILE = 1,
    WHATSAPP = 2,
    EMAIL = 3,
    AUTHENTICATOR = 4
}

export enum LawfirmModule {
    DRIVE = 0,
    FORUM = 1,
    SHARE = 2,
    EDIT_EXCEL = 3,
    WORKFLOW = 5,
    DRM = 6,
    METADATA = 7,
    PERSONAL_FOLDER_ACCESS = 8,
    E_VOTING = 9,
    TIMEFOX = 10,
    ADMIN = 11,
    EXPORT_LOGS = 12,
    WATERMARK = 13,
    VIEWAI = 14,
    IP_MANAGEMENT = 15,
    DASHBOARD = 17
}

export const FILE_VIEW_SUPPORTED = [
    'pdf', 'ppt', 'pptx',
    'doc', 'docx', "xls",
    "xlsx", "csv", "txt",
    'gif', 'jpeg', 'png',
    'jpg', 'tiff', 'tif',
    'eml', 'msg', 'js',
    'docm', 'pptm', "xml",
    "bmp", 'rtf', 'zip',
    'dwg', "xlsb",
    'abw','zabw','swf',
    'agd','fhd','pmd',
    'pm3','pm4','pm5',
    'pm6','p65','ase',
    'pdb','kth','key',
    'numbers','pages','cwk',
    'dxf','bmp','csv',
    'txt','cgm','cdr',
    'cmx','dif','dbf',
    'xml','eps','emf',
    'epub','fb2','gpl',
    'gnm','gnumeric','gif',
    'hwp','plt','html',
    'htm','jtd','jtt',
    'jpg','jpeg','wk1',
    'wks','123','wk3',
    'wk4','pct','mml',
    'met','docx','xlsx',
    'pptx','xls','xlw',
    'xlt','xls','xlw',
    'xlt','xml','xlsx',
    'iqy','docx','xlsx',
    'pptx','pxl','psw',
    'ppt','pps','pot',
    'pptx','pub','rtf',
    'vsd','doc','dot',
    'doc','dot','xml',
    'docx','doc','dot',
    'wps','wks','wdb',
    'wri','pgm','pbm',
    'ppm','odb','odt',
    'fodt','ods','fods',
    'odp','fodp','odg',
    'fodg','odf','sxw',
    'stw','sxc','stc',
    'sxi','sti','sxd',
    'std','sxm','pcx',
    'pcd','psd','txt',
    'pdf','png','qxp',
    'wb2','wq1','wq2',
    'svg','sgv','602',
    'txt','rlf','sgf',
    'sdc','vor','sda',
    'sdd','sdp','vor',
    'sxm','sdw','sgl',
    'vor','ras','svm',
    'slk','tif','tiff',
    'tga','uof','uot',
    'uos','uop','wmf',
    'wpd','wps','xbm',
    'xpm','zmf', "xlsm"
]

export const NORMAL_VIEW_EXT = [
    "doc", "docx", "docm",
    "ppt", "pptx", "pptm",
    "pdf", "jpeg", "jpg",
    "png", "gif", "tif",
    "tiff", "txt", "xml",
    "js", "eml", "msg",
    "rtf","dwg"
]

export const checkViewerExtention = (fileExtension: string, size: number) => {
    if (!fileExtension || !size || size > 5368709120) {
        return false
    } else if (FILE_VIEW_SUPPORTED.includes((fileExtension || '').toLowerCase())) {
        return true
    } else {
        return false
    }
}

export function getFileExtension(filename:string){
	return (/[.]/.exec((filename||""))) ? /[^.]+$/.exec((filename||"")) : "";
}

export const GET_FILE_VIEWER = (fileExtension: string): VIEWER_TYPE => {
    let viewType = CONSTANT.getViewType();
    if (!NORMAL_VIEW_EXT.includes((fileExtension || '').toLowerCase())) {
        viewType = VIEWER_TYPE.NORMAL_VIEWER;
    }
    return viewType;
}

export const downloadFile = (res?: HttpResponse<Blob>, fileName?: string) => {
    let _fileName = res?.headers.get('Content-Disposition');
    if (_fileName) {
        _fileName = _fileName.substring(_fileName.indexOf('filename=') + 9);
    }
    if (_fileName?.startsWith(`"`) && _fileName?.endsWith(`"`) || _fileName?.startsWith(`'`) && _fileName?.endsWith(`'`)) {
        _fileName = _fileName.substring(1, _fileName.length - 1);
    }
    exportFile(res?.body, (fileName || _fileName || 'no-name'))
}

export const exportFile = (blob?: Blob | null | undefined, fileName?: string, content?: string) => {
    const link = document.createElement('a');
    if (blob) {
        link.href = window.URL.createObjectURL(blob);
    }
    if (content) {
        link.href = encodeURI(content);
    }
    link.download = fileName || 'no-name';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const jsonToCSV = (res?: HttpResponse<Blob>, fileName?: string) => {
    const link = document.createElement('a');
    if (res?.body) {
        link.href = window.URL.createObjectURL(res?.body);
    }
    let _fileName = res?.headers.get('Content-Disposition');
    if (_fileName) {
        _fileName = _fileName.substring(_fileName.indexOf('filename=') + 9);
    }
    if (_fileName?.startsWith(`"`) && _fileName?.endsWith(`"`) || _fileName?.startsWith(`'`) && _fileName?.endsWith(`'`)) {
        _fileName = _fileName.substring(1, _fileName.length - 1);
    }
    link.download = _fileName || fileName || 'no-name';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}



export const getCurrentDateTime = (join?: string) => {
    const d = new Date();
    return `${d.getDate()}${join || '-'}${d.getMonth() + 1}${join || '-'}${d.getFullYear()}${join || ' '}${d.getHours()}${join || ':'}${d.getMinutes()}${join || ':'}${d.getSeconds()}`;
}


// export const FIND_PARAMS_IN_ACTIVATED_ROUTE = (name: string, snapshot: ActivatedRouteSnapshot, fromChild = false, immediateParentSlug = false): any => {
//     if (immediateParentSlug) {
//       if (name && snapshot.parent) {
//         return FIND_PARAMS_IN_ACTIVATED_ROUTE(name, snapshot.parent, fromChild);
//       }
//       return '';
//     } else if (!fromChild) {
//       if (name && snapshot?.params && snapshot?.params[name]) {
//         return snapshot?.params[name];
//       } else if (name && snapshot.parent) {
//         return FIND_PARAMS_IN_ACTIVATED_ROUTE(name, snapshot.parent, fromChild);
//       }
//       return '';
//     } else {
//       if (name && snapshot?.params && snapshot?.params[name]) {
//         return snapshot?.params[name];
//       } else if (name && snapshot?.children?.length && snapshot.children[0]?.firstChild) {
//         return FIND_PARAMS_IN_ACTIVATED_ROUTE(name, snapshot.children[0]?.firstChild, fromChild);
//       }
//       return '';
//     }
//   }

export const FIND_DATA_IN_ACTIVATED_ROUTE = (name: string, snapshot: ActivatedRouteSnapshot | null, fromChilde = false): any => {
    if (!snapshot) { return '' }
    if (!fromChilde) {
        if (name && snapshot?.data && snapshot?.data[name]) {
            return snapshot?.data[name];
        } else if (name && snapshot.parent) {
            return FIND_DATA_IN_ACTIVATED_ROUTE(name, snapshot.parent, fromChilde);
        }
        return '';
    } else {
        if (name && snapshot?.data && snapshot?.data[name]) {
            return snapshot?.data[name];
        } else if (name && snapshot?.children?.length && (snapshot.children[0]?.firstChild || snapshot.firstChild)) {
            return FIND_DATA_IN_ACTIVATED_ROUTE(name, (snapshot.firstChild || snapshot.children[0]?.firstChild), fromChilde);
        }
        return '';
    }
}

export const FILE_SIZE_FORMAT = (value: number | string) => {
    let decimals = 2;
    let bytes: number = 0;
    if (typeof value == 'string') {
        bytes = parseFloat(value);
    } else {
        bytes = value;
    }
    if (!+bytes) {
        return '0 Bytes'
    };
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const SORT_CONFIG = {
    // options: [
    //     { name: 'Default', value: 'id', disabled: false, prop: { folder: 'folderId', file: 'id' } },
    //     { name: 'Name', value: 'name', disabled: false, prop: { folder: 'folderPathLastChild', file: 'fileName' } },
    //     { name: 'Modified Date', disabled: false, value: 'date', prop: { folder: 'fileModifiedDate', file: 'fileLastModified' } },
    //     { name: 'Size', disabled: false, value: 'size', prop: { folder: 'folderSize', file: 'fileSize' } },
    //     { name: 'Index', disabled: false, value: 'index', prop: { folder: 'folderIndex', file: 'fileIndex' } }
    // ],

    getOptions(indexingEnabled?: boolean) {
        return [
            { name: 'Default', value: 'id', disabled: false, prop: { folder: 'folderId', file: 'id' } },
            { name: 'Name', value: 'name', disabled: false, prop: { folder: 'folderPathLastChild', file: 'fileName' } },
            { name: 'Modified Date', disabled: false, value: 'date', prop: { folder: 'fileModifiedDate', file: 'fileLastModified' } },
            { name: 'Size', disabled: false, value: 'size', prop: { folder: 'folderSize', file: 'fileSize' } },
            { name: 'Index', disabled: indexingEnabled || false, value: 'index', prop: { folder: 'folderIndex', file: 'fileIndex' } }
        ]
    },
    selectSort(sortVal: string): { name: string, value: string, prop: { folder: string, file: string } } | null {
        if (sortVal) {
            let selectedSort = this.getOptions().find(i => i.value.toLowerCase() == sortVal.toLowerCase()) as any;
            if (!selectedSort) { return null }
            return selectedSort
        } else {
            return null
        }
    }
}

export const getQueryParameterByName = (name: string, url: string = location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    let query = decodeURIComponent(results[2].replace(/\+/g, ' '));
    return query;
}


export const phone_number_regex = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;


export enum PermissionsFolderIndex {
    // when you fetching folder data there is an attribute folderPermissions and the value will be like "1#1#1#1#1#1#0#0" which is 
    // "download#share#upload#delete#copy#move#downloadoriginal#nevigate" split it by # and 0 means not allow and 1 is allow
    download = 0,
    share,
    upload,
    delete,
    copy,
    move,
    downloadOriginal,
    nevigate

}

export enum PermissionsFileIndex {
    // when you fetching folder data there is an attribute folderPermissions and the value will be like "1#1#1#1#1#1#0#0" which is 
    //"0-share#1-uload#2-delete#3-copy#4-move#5-download#6-downloadoriginal#nevigate" - File split it by # and 0 means not allow and 1 is allow
    share = 0,
    upload,
    delete,
    copy,
    move,
    download,
    downloadOriginal,
    nevigate

}


export const folderPermission = (folderPermissions: string): AllowedAction => {
    // ['download', 'share', 'upload', 'delete', 'copy', 'move', 'downloadOriginal', 'nevigate']
    const allowed = {
        download: checkButtonPermission(folderPermissions, PermissionsFolderIndex.download),
        share: checkButtonPermission(folderPermissions, PermissionsFolderIndex.share),
        upload: checkButtonPermission(folderPermissions, PermissionsFolderIndex.upload),
        delete: checkButtonPermission(folderPermissions, PermissionsFolderIndex.delete),
        copy: checkButtonPermission(folderPermissions, PermissionsFolderIndex.copy),
        move: checkButtonPermission(folderPermissions, PermissionsFolderIndex.move),
        downloadOriginal: checkButtonPermission(folderPermissions, PermissionsFolderIndex.downloadOriginal),
        nevigate: checkButtonPermission(folderPermissions, PermissionsFolderIndex.nevigate),
        onlyNevigate: false
    }
    allowed.onlyNevigate = (['download', 'share', 'upload', 'delete', 'copy', 'move', 'downloadOriginal'] as Array<keyof Omit<AllowedAction, 'print'>>).every(k => !allowed[k])
    return allowed;
}

export const filePermission = (filePermissions: string): AllowedAction => {
    // ['download', 'share', 'upload', 'delete', 'copy', 'move', 'downloadOriginal', 'nevigate']
    const allowed = {
        download: checkButtonPermission(filePermissions, PermissionsFileIndex.download),
        share: checkButtonPermission(filePermissions, PermissionsFileIndex.share),
        upload: checkButtonPermission(filePermissions, PermissionsFileIndex.upload),
        delete: checkButtonPermission(filePermissions, PermissionsFileIndex.delete),
        copy: checkButtonPermission(filePermissions, PermissionsFileIndex.copy),
        move: checkButtonPermission(filePermissions, PermissionsFileIndex.move),
        downloadOriginal: checkButtonPermission(filePermissions, PermissionsFileIndex.downloadOriginal),
        nevigate: checkButtonPermission(filePermissions, PermissionsFileIndex.nevigate),
        onlyNevigate: false
    }
    allowed.onlyNevigate = (['download', 'share', 'upload', 'delete', 'copy', 'move', 'downloadOriginal'] as Array<keyof Omit<AllowedAction, 'print'>>).every(k => !allowed[k])
    return allowed;
}



export const checkButtonPermission = (permValue: string, position: number) => {
    if (!permValue) { return true }
    // folderPermissions
    // var allowed = false;
    // var permissions = permValue.split("#");
    // if (localStorage._zs == "B") {
    //   if ((permissions == "null") || (permissions == "undefined")
    //     || (admcurrentfoldertype == admpersonaltype)) {
    //     allowed = true;
    //   } else if (admcurrentfoldertype == admsharedtype) {
    //     if (permissions[position] == "Y") {
    //       allowed = true;
    //     }
    //   }
    // } else if (localStorage._zs == "I") {
    //   allowed = true;
    // }
    const p = permValue.replace(/,/g, "#").split("#")[position];
    return p == "Y" || p == "1";
}


export const convert_AMPM_to_24Hours = (time: string) => {
    // var time = `4:20 PM`;
    const _hours = time.match(/^(\d+)/),
        _minutes = time.match(/:(\d+)/),
        _AMPM = time.match(/\s(.*)$/);

    let hours = _hours && _hours?.length > 1 ? Number(_hours[1]) : 0;
    let minutes = _minutes && _minutes?.length > 1 ? Number(_minutes[1]) : 0;
    let AMPM = _AMPM && _AMPM?.length > 1 ? _AMPM[1] : '';

    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    let sHours = hours.toString();
    let sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return [sHours, sMinutes];
}


export const toISOString_00Z_coversion = (date: Date | string): string | null => {
    if (!date) { return null };
    if (date instanceof Date) {
        date = date.toISOString();
    }
    const chunks = date.split(':');
    return `${chunks[0]}:${chunks[1]}:00Z`
}


// From OLD UI
export function formatDateToServer(val: any, time?: boolean) {
    var returnDate = "";
    if (val != "") {
        if (time == true) returnDate = formatDate(val);
        else returnDate = moment(val).format('YYYY-MM-DD');
        returnDate = formatAddTZDate(returnDate, time);
    }
    return returnDate;
}

export function padTo2Digits(num:any) {
    return num.toString().padStart(2, '0');
  }

export function formatDate(date: any) {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes())
      ].join(':')
    );
  }


export function formatAddTZDate(dt: any, timepresenet?: boolean) {
    var rettime = "00:00";
    if (timepresenet) {
        rettime = dt.substring(dt.indexOf(" ") + 1);
        dt = dt.substring(0, dt.indexOf(" "));
    }
    dt = moment(dt).format("YYYY-MM-DD");
    dt = dt + "T" + rettime + ":00Z";
    return dt;
}

export function isValidEmail(email: string): boolean {
    const emailPattern = /^(.+)@(.+)$/;
    return emailPattern.test(email);
  }
  
  export function isValidPhoneNumber(phoneNumber: string): boolean {
    const phonePattern = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
    return phonePattern.test(phoneNumber);
  }

  export function hasNonAsciiCharacters(str:string) {
    return /[^\x00-\x7F]/.test(str);
  }
  
  
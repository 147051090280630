import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MsgPopupComponent } from './components/msg-popup/msg-popup.component';

@Injectable()
export class MsgPopupService {


  constructor(public dialog: MatDialog) { }

  openMsgPopup = (data: {
    messages: { title?: string, subTitle?: string, subTitles?: string[] },
    closeable?: boolean,
    confirmation?: boolean,
    btns?: { close: { title: string }, continue: { title: string } },
    theme?: 'default' | 'warn' | 'danger',
    align?: "center" | "start" | "end"
  }): MatDialogRef<MsgPopupComponent, any> => this.dialog.open(MsgPopupComponent, {
    width: 'auto',
    maxWidth: '1000px',
    minWidth: '200px',
    disableClose: true,
    closeOnNavigation: true,
    data: {
      theme: data.theme,
      messages: data.messages || {},
      closeable: data.closeable || false,
      confirmation: data.confirmation || false,
      btns: {
        close: { title: data?.btns?.close?.title || 'Close' },
        continue: { title: data?.btns?.continue?.title || 'Continue' }
      },
      align: data?.align || "start"
    }
  })
}

import { HttpEvent } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FolderType } from '../constants/constant';
import { FileOrFolder } from '../models/file-folder.model';
import { PbUploadFileFolder, PbDropedData } from '../public-modules/shared/upload-pb.service';


export interface DownloadItem {
  label: string,
  downloadObservable: Observable<HttpEvent<any>>,
  isProcessing: boolean,
  progress: number,
  state: "PENDING" | "IN_PROGRESS" | "DONE",
  subscription?: Subscription,
  errormsg: string
}

export interface UploadFile {
  label: string,
  uploadObservable: Observable<HttpEvent<any>>,
  isProcessing: boolean,
  progress: number,
  state: "PENDING" | "IN_PROGRESS" | "DONE",
  subscription?: Subscription
}

export interface UploadFileFolder {
  files: FileList,
  type: 'folder' | 'file',
  folder: any,
  folderType: FolderType,
  otherUpload?: boolean
}

export interface DropedData {
  arr: Array<FileSystemEntry>,
  folder: FileOrFolder | null,
  folderType: FolderType
}





@Injectable({
  providedIn: 'root'
})
export class DownloadNotifierService {
  downloadingItems: EventEmitter<DownloadItem> = new EventEmitter();
  uploadItems: EventEmitter<UploadFileFolder> = new EventEmitter();

  onDropItems: EventEmitter<DropedData> = new EventEmitter();

  importOtherFiles: EventEmitter<UploadFile> = new EventEmitter();
  importOtherFilesDone: EventEmitter<boolean> = new EventEmitter();

  uploadItemsDone: EventEmitter<{ folder: any, folderType: FolderType }> = new EventEmitter();


  uploadItemsPb: EventEmitter<PbUploadFileFolder> = new EventEmitter();
  onDropItemsPb: EventEmitter<PbDropedData> = new EventEmitter();
  uploadItemsDonePb: EventEmitter<{ folder: any, folderType: FolderType }> = new EventEmitter();
  uploadItemsStartsPb: EventEmitter<{ folder: any, folderType: FolderType }> = new EventEmitter();


}

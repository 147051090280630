import { Injectable } from '@angular/core';
// import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { FolderUploadComponent } from './components/folder-upload/folder-upload.component';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { CONSTANT_API } from 'src/app/constants/constant-apis';
import { CONSTANT, FolderType } from 'src/app/constants/constant';
import { of, switchMap, forkJoin, Observable, Subscription } from 'rxjs';

export type FilesFolderWise = {
  folderId?: string,
  folderPath?: string,
  parent?: { folderId: string, folderPath: string },
  downloadObservable?: Observable<HttpEvent<any>>,
  isProcessing?: boolean,
  progress?: number,
  state?: "PENDING" | "IN_PROGRESS" | "DONE",
  subscription?: Subscription
  files: Array<{
    name: string,
    
    size: number,
    type: string,
    file: File,
    storageFileName?:string,
    fileId?:string,
    downloadObservable?: Observable<HttpEvent<any>>,
    isProcessing: boolean,
    progress: number,
    state: "PENDING" | "IN_PROGRESS" | "DONE",
    subscription?: Subscription,
    aws_url?: string
  }>
}

export type FolderUploadData = {
  [key: string]: {
    folderId?: string,
    folderPath?: string,
    parent?: { folderId: string, folderPath: string }
    files: Array<File>,
  }
}

@Injectable()
export class FolderUploadService {

  constructor(
    // public dialog: MatDialog,
    private http: HttpClient) { }

  // openDialog = (folderType: FolderType, folder?: any, fileUpload?: boolean): MatDialogRef<FolderUploadComponent, any> => this.dialog.open(FolderUploadComponent, {
  //   width: '99vw',
  //   maxWidth:'1000px',
  //    disableClose: true,
      // closeOnNavigation:true,
  //   data: {
  //     folderType,
  //     folder,
  //     fileUpload
  //   }
  // })

  newAtc({ data, folderType, folder }: { data: FolderUploadData, folderType: FolderType, folder?: any }) {
    const payload = {
      attribute1: folderType,
      listAttribute1: Object.keys(data).map((key, index) => `${key}#${(index == 0 && folder?.folderId) ? folder?.folderId : 0}`)
    }
    return this.http.post<any>(CONSTANT_API.newAtc, payload)
    // { reportProgress: true, observe: 'events' }
    // .pipe(switchMap(newAtcRes => {
    //   if (newAtcRes.error) { return of(newAtcRes) }
    //   return this.atc({ newAtcRes, data, folderType, folder })
    // }))
  }

  atc({ data, folderType }: { data: FolderUploadData, folderType: FolderType, }) {
    // ((newAtcRes?.objectD || []) as Array<string>)?.forEach(item => {
    //   const chunks = item.split('#');
    //   if (data[chunks[0]]) {
    //     data[chunks[0]].folderId = chunks[1]
    //   }
    // });
    const files = Object.keys(data).map(key => data[key].files.map(file => ({
      attribute1: data[key].folderId,
      // folderPath: key,
      attribute2: file.name,
      attribute3: file.size,
      // file: file
    }))).flat();
    const payload = {
      attribute1: folderType,
      listAttribute5: files// files.map(({ attribute1, attribute2, attribute3 }: any) => ({ attribute1, attribute2, attribute3 }))
    }
    return this.http.post<any>(CONSTANT_API.atc, payload)
    // .pipe(switchMap(atcRes => {
    //   if (atcRes.error) { return of(atcRes) }
    //   return forkJoin((atcRes.object as Array<any>).map(item => {
    //     const awsURL = item.attribute3;
    //     const file = files.find(f => f.file.name == item.attribute2);
    //     return this.upload_to_aws(awsURL, file?.file, file?.folderPath)
    //   })).pipe(switchMap(_ => this.admDocAddMetaDataServiceAfter({ folderType, files })))
    // }))
  }


  upload_to_aws(awsURL: string, file: File, folderPath: string, fileNameReceivedFromAtc: string,) {
    const headers = new HttpHeaders({
      // 'Content-Length': `${file?.size}`,
      'folderpath': folderPath,
      'Filename': fileNameReceivedFromAtc,
      'Content-Type': file?.type

    })
    return this.http.put(awsURL, file, { headers, reportProgress: true, observe: 'events' })
  }

  admDocAddMetaDataServiceAfter({ folderType, files }: { folderType: FolderType, files: Array<any> }) {
    const user = CONSTANT.getUser();
    const payload = {
      listAttribute4: files.map(file => ({
        "userId": user.id,
        "folderId": file.folderId,
        "parentFolderId": 0,
        "storageFileName": file.storageFileName,
        "fileName": file.name,
        "fileSize": file.size,
        "fileType": file.type,
        "status": "A",
        "deleteStatus": "",
        "folderType": folderType,
        "fileUniqueId": "",
        "fileId": file.fileId
      })),
      "attribute12": 1,
      "attribute13": files[0].folderPath.split('/')[0],
      "boolAttribute1": true
    }
    return this.http.post(CONSTANT_API.admDocAddMetaDataServiceAfter, payload)
  }

  admCheckStorageleft() {
    return this.http.post<any>(CONSTANT_API.fetchstoragespace, { "attribute1": "1" });
  }
}

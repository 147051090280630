<div *ngIf="items?.length" class="dc-bg-light z-index-1 shadow rounded downloading-conatiner">
    <mat-accordion class="w-100">
        <mat-expansion-panel class="shadow-none dc-bg-light" [expanded]="true">
            <mat-expansion-panel-header class="px-4">
                <mat-panel-title class="">
                    <span class="flex-fill typo-ps-body-3 dc-text-light-dark fw-normal d-flex align-items-center justify-content-between"><span>{{downloadTitle}}</span> <span (click)="cancelDownload()" role="button" class="dc-text-secondary fs-6 material-icons ms-5">clear</span></span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list class="dc-bg-light-xs rounded list-max-h">
                <mat-list-item *ngFor="let item of items">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center">
                            <span class="material-icons fs-5 me-2 dc-text-light-md">article</span>
                            <span class="typo-ps-body-3 dc-text-secondary">{{item.label}}</span>
                        </div>
                        <mat-spinner *ngIf="item.isProcessing" class="ms-3" [diameter]="15"></mat-spinner>
                        <span *ngIf="!item.isProcessing" class="ms-3 dc-text-success fs-4 material-icons">check_circle </span>
                    </div>
                    <mat-progress-bar *ngIf="item.isProcessing" [mode]="item.state == 'PENDING' ? 'buffer' : 'determinate'" [value]="item.progress"></mat-progress-bar>
                </mat-list-item>
            </mat-list>
        </mat-expansion-panel>
    </mat-accordion>
</div>
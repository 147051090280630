import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadNotifierService, DropedData, UploadFile, UploadFileFolder } from 'src/app/services/download-notifier.service';
import { FilesFolderWise, FolderUploadData, FolderUploadService } from '../../folder-upload/folder-upload.service';
import { HttpClientModule, HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FileSizePipeModule } from 'src/app/pipes/file-size-pipe/file-size-pipe.module';
import { FileSizePipe } from 'src/app/pipes/file-size-pipe/file-size.pipe';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MsgPopupModule } from '../../shared/msg-popup/msg-popup.module';
import { FolderType, getCurrentDateTime, CONSTANT } from 'src/app/constants/constant';
import { Subscription, map, catchError, throwError } from 'rxjs';
import { SnackService } from 'src/app/services/snack.service';
import { MsgPopupService } from '../../shared/msg-popup/msg-popup.service';
import { FileOrFolder } from 'src/app/models/file-folder.model';
import { MissionService } from 'src/app/services/mission.service';
import { dataroom_messages } from 'src/app/constants/dataroom';
import { CommonJSService } from 'src/app/common/common-js-service';

interface FileWithDir {
  path: string,
  file: File
}

@Component({
  selector: 'dcrs-upload',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    FileSizePipeModule,
    MatListModule,
    MatExpansionModule,
    MsgPopupModule],
  providers: [FolderUploadService, FileSizePipe],
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  errorfiles: Array<{folerPath:string,name:string,success:string,error:string}> = [];
  folderUnSupportedChr: boolean = false;
  folderUnSupportedChrName:string = "";

  files: Array<File> = [];
  folderFiles: Array<File> = [];
  folders: Array<FilesFolderWise> = [];
  folderData!: FolderUploadData

  folder!: any;
  folderType!: FolderType;
  fileUpload!: boolean

  uploadTitle = 'Uploading...';
  isProcessing!: boolean
  subscription: Subscription = new Subscription();

  otherFiles: Array<UploadFile> = [];
  importFileTitle = 'Uploading...';

  @Input() uploadFileFolders!: UploadFileFolder;
  @Input() otherFile!: UploadFile;
  @Input() dropedData!: DropedData;

  constructor(
    private snackService: SnackService,
    private missionService:MissionService,
    private msgPopupService: MsgPopupService,
    private downloadNotifierService: DownloadNotifierService,
    private folderUploadService: FolderUploadService,
    private fileSizePipe: FileSizePipe,
    private commonJSService: CommonJSService) {
  }

  ngOnInit(): void {
    
  }

  initUploadFileFolders(val: UploadFileFolder) {
    let filesArr: Array<File> = Array.from(val?.files?.length && val?.files || []);
    let disAllowedCharactersCount= 0;
    let fileSizeTotal = 0;
    filesArr.forEach((file: File) => {
      fileSizeTotal += file.size;
      let fdnm = file.webkitRelativePath.substring(0, file.webkitRelativePath.lastIndexOf("/"));
      if(file.webkitRelativePath.length<256 && file.name.length<256){
        if(disAllowedCharactersCount==0 && val.type=="folder"){
          if(!this.checkAllowedCharacters(fdnm, "file")){
            disAllowedCharactersCount++;
            this.snackService.error(dataroom_messages.folderuploadnotallow(
              this.commonJSService.findFolderFileNameWithNonAllowedChr(fdnm)));
          }
        }
        if(!this.checkAllowedCharacters(file.name, "file")){
          this.errorfiles.push({folerPath:fdnm,name:file.name,success:"NO",error:dataroom_messages.notsupportedcharacters});
        } else if(disAllowedCharactersCount==0){
          this.files[this.files.length] = file;
        }
      } else {
        this.errorfiles.push({folerPath:fdnm,name:file.name,success:"NO",error:dataroom_messages.folderuploaddomexception});
      }
    });

    if(disAllowedCharactersCount>0 && val.type=="folder") this.files = [];
    else if(val.type=="file" && this.errorfiles.length==filesArr.length) {
      this.snackService.error(dataroom_messages.notsupportedcharacters);
      this.errorfiles = [];
    }else if(val.type=="file") disAllowedCharactersCount=0;

    if(disAllowedCharactersCount==0){
      this.folderUploadService.admCheckStorageleft().subscribe({
        next: res => {
          const attr2 = res?.object?.attribute2;
          const allocated = parseFloat(attr2.split("#")[0]);
          const used = parseFloat(attr2.split("#")[1]);
          if((allocated-used)<fileSizeTotal){
            this.files = [];
            var msg = dataroom_messages.uploadexceedssizeleft;
            let sizeLeft = this.fileSizePipe.transform((allocated-used) < 0 ? 0 : (allocated-used));
            let uploadSize = this.fileSizePipe.transform(fileSizeTotal);
            msg = msg.replace("<&LEFTSPACE;>", sizeLeft).replace("<&CURUPLOADSPACE;>", uploadSize);
            this.snackService.error(msg);
          } else {
            this.fileUpload = val.type == 'file';
            this.folder = val.folder;
            this.folderType = val.folderType;
            this.createData();
          }
        }, error: err => {
          this.snackService.error(dataroom_messages.couldnotprocessrequest)
        }
      })
    }
  }

  onUploadOtherFiles(item: UploadFile) {
    if (item) {
      this.otherFiles.push(item);
      this.importFileTitle = this.otherFiles.every(i => !i.isProcessing) ? 'Upload Completed' : 'Uploading...';
      item['subscription'] = item.uploadObservable.subscribe({
        next: event => {
          console.log('Upload Event', event, item);
          switch (event.type) {
            case HttpEventType.UploadProgress:
              item.state = 'IN_PROGRESS';
              item.progress = (event.loaded * 100) / (event.total || event.loaded);
              break;
            case HttpEventType.Response:
              item.state = 'DONE';
              item.progress = 100;
              item.isProcessing = false;
              const allDownloaded = this.otherFiles.every(i => !i.isProcessing);
              this.importFileTitle = allDownloaded ? 'Upload Completed' : 'Uploading...';
              if (allDownloaded) {
                this.snackService.success(`${this.otherFiles.length} item(s) Uploaded successfully`);
                this.downloadNotifierService.importOtherFilesDone.emit(true);
                const st = setTimeout(() => {
                  this.otherFiles.forEach(i => i.subscription?.unsubscribe());
                  const _st = setTimeout(() => {
                    this.otherFiles = [];
                    clearTimeout(st);
                    clearTimeout(_st);
                  }, 100);
                }, 500);
              }
              break;
          }
        },
        error: (err: HttpErrorResponse) => {
          console.log('Upload Error', err, item)
          item.state = 'DONE';
          item.progress = 100;
          item.isProcessing = false;
          const allDownloaded = this.otherFiles.every(i => !i.isProcessing);
          this.importFileTitle = allDownloaded ? 'Upload Completed' : 'Uploading...';
          if (allDownloaded) {
            this.snackService.success(`${this.otherFiles.length} item(s) Uploaded successfully`);
            this.downloadNotifierService.importOtherFilesDone.emit(true);
            const st = setTimeout(() => {
              this.otherFiles.forEach(i => i.subscription?.unsubscribe());

              const _st = setTimeout(() => {
                this.otherFiles = [];
                clearTimeout(st);
                clearTimeout(_st);
              }, 100);
            }, 500);
          }
          var encodedUri = window.URL.createObjectURL(new Blob([err.error.text || ''], { type: 'text/csv' }));
          var link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", `useruploadstatus.csv`);
          document.body.appendChild(link); // Required for FF
          link.click(); // This will download the data file named "my_data.csv".
          document.body.removeChild(link);
        }
      });
    }
  }

  init() {
    if (!this.otherFile) {
      this.folderUploadService.admCheckStorageleft().subscribe({
        next: res => {
          const attr2 = res?.object?.attribute2;
          const allocated = parseFloat(attr2.split("#")[0]);
          const used = parseFloat(attr2.split("#")[1]);
          if((allocated-used)<=0){
            var msg = dataroom_messages.allocatedspaceover.replace("<&ALLOCATEDSPACE;>", this.fileSizePipe.transform(allocated));
            this.snackService.error(msg);
          } else {
            if (this.uploadFileFolders) {
              this.initUploadFileFolders(this.uploadFileFolders)
            }
            if (this.dropedData) {
              this.onDropData(this.dropedData)
            }
          }
        }, error: err => {
          this.snackService.error(dataroom_messages.couldnotprocessrequest)
        }
      })
    } else if (this.otherFile) {
      this.onUploadOtherFiles(this.otherFile)
    }
  }

  cancelOtherImports() {
    if (this.otherFiles.some(i => i.isProcessing)) {
      this.msgPopupService.openMsgPopup({
        theme: 'default',
        confirmation: true,
        closeable: false,
        messages: {
          title: 'Cancel Upload',
          subTitle: 'Are you sure you want to cancel download?'
        },
        btns: { close: { title: 'Continue Upload' }, continue: { title: 'Cancel Upload' } }
      }).afterClosed().subscribe(val => {
        if (val) {
          this.otherFiles.forEach(i => i.subscription?.unsubscribe());
          setTimeout(() => {
            this.otherFiles = [];
            this.downloadNotifierService.importOtherFilesDone.emit(true);
          }, 100);
        }
      })
    } else {
      this.otherFiles = [];
      this.downloadNotifierService.importOtherFilesDone.emit(true);
    }
  }

  addSubscription(subscription: Subscription) {
    if (!this.subscription.add) {
      this.subscription = new Subscription();
    }
    this.subscription.add(subscription);
  }


  createData() {
    this.folderData = {};
    if (!this.files?.length) { return }
    if (this.fileUpload) {
      this.folderData = {
        [this.folder?.folderPath]: {
          folderId: this.folder?.folderId,
          folderPath: this.folder?.folderPath,
          files: this.files
        }
      };
    } else {
      this.files.forEach((file: File) => {
        const path: string = file['webkitRelativePath'];
        const pathPieces = path.split('/');
        pathPieces.pop();
        const folderPath = `${this.folder?.folderPath ? (this.folder?.folderPath + '/') : ''}${pathPieces.join('/')}`;
        if (this.folderData[folderPath]) {
          this.folderData[folderPath].files.push(file);
        } else if (!this.folderData[folderPath]) {
          this.folderData[folderPath] = { folderPath: folderPath, files: [file], parent: { folderId: this.folder?.folderId, folderPath: this.folder?.folderPath } };
        }
      });
    }
    this.generateFolders();
    this.fileUpload ? this.uploadFiles() : this.uploadFolder();
  }

  generateFolders() {
    this.folders = Object.keys(this.folderData || {}).map(key => ({
      folderPath: key,
      folderId: this.folderData[key].folderId,
      parent: this.folderData[key].parent,
      files: this.folderData[key].files.map(file => ({
        file: file,
        name: file.name,
        size: file.size,
        type: file.name.split('.').pop() || file.type.split('/')[1],
        isProcessing: true,
        progress: 0,
        state: "PENDING",
      }))
    }));
  }

  uploadFolder() {
    this.uploadTitle = `Preparing ${this.files.length} files to upload`;
    this.isProcessing = true;
    let subscription = this.folderUploadService.newAtc({ data: this.folderData, folderType: this.folderType, folder: this.folder }).subscribe({
      next: newAtcRes => {
        ((newAtcRes?.objectD || []) as Array<string>)?.forEach(item => {
          const chunks = item.split('#');
          if (this.folderData[chunks[0]]) {
            this.folderData[chunks[0]].folderId = chunks[1]
          }
        });
        this.generateFolders();
        this.uploadFiles();
      }, error: err => {
        this.isProcessing = false;
        this.snackService.error(JSON.stringify(err?.body))
      }
    });
    this.addSubscription(subscription);
  }

  uploadFiles() {
    this.isProcessing = true;
    this.uploadTitle = `Uploading ${this.files.length} files`;
    const subscription = this.folderUploadService.atc({ data: this.folderData, folderType: this.folderType }).subscribe({
      next: atcRes => {
        (atcRes.object as Array<any>).forEach(item => {
          const {
            attribute3: awsURL,
            attribute2: fileNameReceivedFromAtc,
            attribute1: folderId,
            attribute5: originalFileName,
            attribute4: fileId,
            attribute7: storageFileName,
            attribute8: folerpath
          } = item;
          this.folders.forEach(folder => {
            if (folder.folderId == folderId) {
              folder.files.forEach(file => {
                try{
                  if (file.name == originalFileName) {
                    file.aws_url = (awsURL as string)?.replace('http://', 'https://');
                    file.storageFileName = storageFileName;
                    file.fileId = fileId;
                    file.downloadObservable = this.folderUploadService.upload_to_aws((file?.aws_url || ''), file.file, (folerpath || ''), storageFileName).pipe(
                      map(event => {
                        console.log("event.type : " +event.type);
                        switch (event.type) {
                          case HttpEventType.DownloadProgress:
                            file.state = 'IN_PROGRESS';
                            file.progress = (event.loaded * 100) / (event.total || event.loaded);
                            break;
                          case HttpEventType.Response:
                            file.state = 'DONE';
                            file.progress = 100;
                            file.isProcessing = false;
                            const files = this.folders.map(folder => folder.files).flat();
                            const allDownloaded = files.every(i => !i.isProcessing);
                            this.uploadTitle = allDownloaded ? 'Upload Completed' : 'Uploading...';
                            if (allDownloaded) {
                              this.admDocAddMetaDataServiceAfter();
                            }
                            break;
                        }
                        return event;
                      }),
                      catchError( err => throwError(() => this.handleError(file, folder.folderId)) )
                    );
                    file.subscription = file.downloadObservable?.subscribe();
                  }
                }catch(error){
                  file.state = 'DONE';
                  file.progress = 100;
                  file.isProcessing = false;
                }
              })
            }
          })
        });
        this.isProcessing = false;
      }, error: err => {
        this.isProcessing = false;
        const msg = err.body.message+"";
        if(msg.indexOf("Size: 0")>=0){
          this.snackService.error(JSON.stringify(err?.body))
        }
        this.snackService.error(JSON.stringify(err?.body))
      }
    })
    this.addSubscription(subscription)
  }

  handleError(file:any, folderId:any) {
    let keyd:any = "";
    let keyf:any = "";
    this.folders.forEach(function (item, key, mapObj) {
      console.log(key.toString(), ":", " ", item.toString());
      item.files.forEach(function (itemnext, keynext, mapObjnext) {
        console.log("file.name : " + file.name + " :: itemnext.file.name : " + itemnext.file.name +
        " :: item.folderId :" + item.folderId + " :: folderId : " + folderId);
        if(file.name==itemnext.file.name && item.folderId==folderId){
          keyf = key;
          keyd = keynext;
        }
      });
    });

    delete this.folders[keyf].files[keyd];
    const files = this.folders.map(folder => folder.files).flat();
    file.state = 'DONE';
    file.progress = 100;
    file.isProcessing = false;
    this.errorfiles.push({folerPath:file.file.webkitRelativePath,name:file.file.name,success:"NO",error:dataroom_messages.folderuploaddomexception});
    if(files.length==0){
      this.files = [];
      this.folders = [];
      this.folderData = {};
      this.cancelUpload();
      if (!navigator.onLine) {
        this.snackService.error(dataroom_messages.serverrestart)
      } else {
        this.snackService.error(dataroom_messages.folderuploaddomexception)
      }
    } else {
      const allDownloaded = files.every(i => !i.isProcessing);
      this.uploadTitle = allDownloaded ? 'Upload Completed' : 'Uploading...';
      if (allDownloaded) {
        this.admDocAddMetaDataServiceAfter();
      }
    }
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  cancelUpload() {
    if (this.folders.map(folder => folder.files).flat().some(i => i.isProcessing)) {
      this.msgPopupService.openMsgPopup({
        theme: 'default',
        confirmation: true,
        closeable: false,
        messages: {
          title: 'Cancel Upload',
          subTitle: 'Are you sure you want to cancel upload?'
        },
        btns: { close: { title: 'Continue Upload' }, continue: { title: 'Cancel Upload' } }
      }).afterClosed().subscribe(val => {
        if (val) {
          this.folders.map(folder => folder.files).flat().forEach(i => i.subscription?.unsubscribe());
          setTimeout(() => {
            this.files = [];
            this.folders = [];
            this.folderData = {};
          }, 100);
        }
      })
    } else {
      this.files = [];
      this.folders = [];
      this.folderData = {};
    }
    this.subscription?.unsubscribe();
    this.subscription = new Subscription();
  }

  admDocAddMetaDataServiceAfter() {
    this.isProcessing = true;
    this.uploadTitle = 'Finishing up...';
    let _files = this.folders.map(folder => folder.files.map(file => ({
      folderId: folder.folderId,
      name: file.name,
      storageFileName: file.storageFileName,
      type: file.type,
      size: file.size,
      fileId: file?.fileId || '0',
      folderPath: folder.folderPath,
      success: "Yes",
      error: ""
    }))).flat();

    this.folderUploadService.admDocAddMetaDataServiceAfter({
      folderType: this.folderType,
      files: _files
    }).subscribe({
      next: report => {
        this.uploadTitle = 'Upload Completed';
        const files = this.folders.map(folder => folder.files).flat()
        this.snackService.success(`${files.length} item(s) uploaded successfully`)
        const st = setTimeout(() => {
          files.forEach(i => i.subscription?.unsubscribe());
          const _st = setTimeout(() => {
            this.files = [];
            this.folders = [];
            this.folderData = {};
            clearTimeout(st);
            clearTimeout(_st);
          }, 100);
        }, 500);

        this.errorfiles.forEach(filen => {
          _files.push({
            folderId: "",
            name: filen.name,
            storageFileName: "",
            type: "",
            size: 0,
            fileId: "0",
            folderPath: (this.folder?this.folder.folderPath+"/":"")+filen.folerPath,
            success: filen.success,
            error: filen.error
          });
        });

        let csvContent = [
          ['\uFEFFFolder Path', 'File Name', 'Success', 'Error'].join(','),
          ..._files.map(file => [file.folderPath, file.name, file.success, file.error].join(','))
        ].join('\n')
        
        var encodedUri = window.URL.createObjectURL(new Blob([csvContent], { type: 'text/csv' }));
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${CONSTANT.getUser().lawFirmId}_Upload_Report_${getCurrentDateTime('_')}`);
        document.body.appendChild(link); // Required for FF
        link.click(); // This will download the data file named "my_data.csv".
        document.body.removeChild(link);
        this.isProcessing = false;
        this.downloadNotifierService.uploadItemsDone.emit({ folder: this.folder, folderType: this.folderType });
        this.missionService.folderUpdateOnTreeMission({ folder: this.folder });
        this.errorfiles.length=0;
      }, error: err => {
        this.isProcessing = false;
        if (!navigator.onLine) {
          this.snackService.error(dataroom_messages.serverrestart)
        } else {
          this.snackService.error(JSON.stringify(err?.body));
        }
      }
    })
  }

  onDropData({ arr, folder, folderType }: DropedData) {
    this.processFiles(arr, folder, folderType)
  }

  async processFiles(arr: Array<FileSystemEntry>, folder: FileOrFolder | null, folderType: FolderType) {
    // const files: Array<File> = [];
    let disAllowedCharactersCount = 0;
    let filesinglerrror = false;
    let type = "file";
    let directoryFiles: Array<FileWithDir> = [];
    const files: Array<FileWithDir> = [];
    for (const entry of arr) {
      var fdnm = entry.fullPath;
      if(fdnm.charAt(0)=="/") fdnm = fdnm.substring(1);
      if(fdnm.length<256){
        if (entry?.isFile) {
          const fileData = await this.parseFileEntry(entry as FileSystemFileEntry, entry.fullPath);
          if(!this.checkAllowedCharacters(fileData.file.name, "file")){
            disAllowedCharactersCount++;
            fdnm = fdnm.substring(0, fdnm.lastIndexOf("/"));
            this.errorfiles.push({folerPath:fdnm,name:fileData.file.name,success:"NO",error:dataroom_messages.notsupportedcharacters});
            filesinglerrror = true;
          } else {
            files.push(fileData);
          }
        } else if (entry?.isDirectory) {
          const files = await this.parseDirectoryEntry(entry as FileSystemDirectoryEntry);
          if(files.length>0){
            directoryFiles.push(...files);
          } else {
            disAllowedCharactersCount++;
          }

          if(!this.checkAllowedCharacters(fdnm, "folder")){
            disAllowedCharactersCount++;
            this.folderUnSupportedChr = true;
            this.folderUnSupportedChrName = fdnm;
          }
        }
      } else {
        fdnm = fdnm.substring(0, fdnm.lastIndexOf("/"));
        if (entry?.isFile) {
          const fileData = await this.parseFileEntry(entry as FileSystemFileEntry, entry.fullPath);
          this.errorfiles.push({folerPath:fdnm,name:fileData.file.name,success:"NO",error:dataroom_messages.notsupportedcharacters});
        } else if (entry?.isDirectory) {
          disAllowedCharactersCount++;
          this.folderUnSupportedChr = true;
          this.folderUnSupportedChrName = fdnm;
        }
      }
    }

    if(this.folderUnSupportedChr) {
      disAllowedCharactersCount++;
      directoryFiles=[];
      this.snackService.error(dataroom_messages.folderuploadnotallow(
        this.commonJSService.findFolderFileNameWithNonAllowedChr(this.folderUnSupportedChrName)));
    } else if(filesinglerrror && directoryFiles.length==0 && arr.length==this.errorfiles.length && disAllowedCharactersCount>0) {
      this.snackService.error(dataroom_messages.notsupportedcharacters);
      this.errorfiles = [];
    }

    if(directoryFiles.length>0) type = "folder";
    if (files.length) {
      if (folder?.folderId) {
        directoryFiles.push(...files);
      } else {
        this.snackService.warn(`For uploading files. Please select folder.`)
      }
    }

    this.files = [];
    this.folderData = {};

    this.folderType = folderType;
    this.folder = folder;

    if (!directoryFiles.length) {
      return;
    }

    let fileSizeTotal = 0;
    directoryFiles.forEach(({ file, path }) => {
      fileSizeTotal += file.size;
    });

    if(disAllowedCharactersCount>0 && type=="folder") this.files = [];
    else if(type=="file") disAllowedCharactersCount=0;
    
    if(disAllowedCharactersCount==0){
      this.folderUploadService.admCheckStorageleft().subscribe({
        next: res => {
          const attr2 = res?.object?.attribute2;
          const allocated = parseFloat(attr2.split("#")[0]);
          const used = parseFloat(attr2.split("#")[1]);
          if((allocated-used)<fileSizeTotal){
            this.files = [];
            var msg = dataroom_messages.uploadexceedssizeleft;
            let sizeLeft = this.fileSizePipe.transform((allocated-used) < 0 ? 0 : (allocated-used));
            let uploadSize = this.fileSizePipe.transform(fileSizeTotal);
            msg = msg.replace("<&LEFTSPACE;>", sizeLeft).replace("<&CURUPLOADSPACE;>", uploadSize);
            this.snackService.error(msg);
          } else {
            directoryFiles.forEach(({ file, path }) => {
              this.files.push(file);
              const fullPath: string = path;
              const pathPieces = fullPath.split('/');
              pathPieces.pop();
              const joinedPath = pathPieces.join('/');
              let folderPath = `${this.folder?.folderPath ? (this.folder?.folderPath + '/') : ''}${joinedPath}`;
              if (!joinedPath && !this.folder) {
                folderPath = `root`;
              }
        
              if (folderPath.endsWith('/')) {
                folderPath = folderPath.replace(/.$/, "");
              }
              if (this.folderData[folderPath]) {
                this.folderData[folderPath].files.push(file);
              } else if (!this.folderData[folderPath]) {
                this.folderData[folderPath] = { folderPath: folderPath, files: [file], parent: { folderId: this.folder?.folderId, folderPath: this.folder?.folderPath } };
              }
            });
            this.generateFolders();
            this.uploadFolder();
          }
        }, error: err => {
          this.snackService.error(dataroom_messages.couldnotprocessrequest)
        }
      })
    }
  }

  parseFileEntry = (fileEntry: FileSystemFileEntry, path: string) => new Promise<FileWithDir>((resolve, reject) => {
    fileEntry.file((file: File) => {
      if (path.startsWith('/')) {
        path = path.replace('/', '')
      }
      resolve({ path, file })
    }, (err: DOMException) => reject(err));
  });

  parseDirectoryEntry(directoryEntry: FileSystemDirectoryEntry) {
    const directoryReader = directoryEntry.createReader();
    return new Promise<Array<FileWithDir>>((resolve, reject) => {
      directoryReader.readEntries(
        async (entries: FileSystemEntry[]) => {
          let files: Array<FileWithDir> = []
          for (const fileEntry of entries) {
            if (fileEntry.isDirectory) {
              try{
                const _files = await this.parseDirectoryEntry(fileEntry as FileSystemDirectoryEntry);
                let disAllowedCharactersCount = 0;
                for (const _file of _files) {
                  let pth = _file.path.substring(0, _file.path.lastIndexOf("/"));
                  if(!this.checkAllowedCharacters(pth, "file")){
                    disAllowedCharactersCount++;
                    this.folderUnSupportedChrName=pth;
                  }
                }
                if(disAllowedCharactersCount==0){
                  files.push(..._files);
                } else {
                  this.folderUnSupportedChr = true;
                }
              }catch(error){
                console.log("directory read error : " + error);
              }
            } else if (fileEntry.isFile && !this.folderUnSupportedChr) {
              try{
                const file = await this.parseFileEntry(fileEntry as FileSystemFileEntry, fileEntry.fullPath);
                var fdnm = file.path.substring(0, file.path.lastIndexOf("/"));
                if(!this.checkAllowedCharacters(file.file.name, "file")){
                  this.errorfiles.push({folerPath:fdnm,name:file.file.name,success:"NO",error:dataroom_messages.notsupportedcharacters});
                } else {
                  files.push(file);
                }
              }catch(error){
                console.log("file read error : " + error);
              }
            }
          }
          if(this.folderUnSupportedChr){
            files = [];
          }
          resolve(files);
        },
        (err: DOMException) => {
          reject(err);
        }
      );
    });
  }

  checkAllowedCharacters(filename:string, type:string){
    let allowedCharacters = this.commonJSService.checkValidCharacters(filename, '/');
    if(!allowedCharacters && type=="folder"){
      this.snackService.error(dataroom_messages.notsupportedcharacters);
    }
    return allowedCharacters;
  }
}

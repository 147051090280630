import { environment } from 'src/environments/environment'
import { VIEWER_TYPE, getCurrentDateTime } from './constant';
export class CONSTANT_API {
    static ipAddressApi = `https://api.ipify.org/?format=json`;
    static loginFetchCaptchaAfter = `${environment.acms_api_v1}/new/cpt/0/loginFetchCaptchaAfter`;
    static fileListCheckCaptcha = `${environment.acms_api_v1}/public/filelistcheck/0/gen/captcha`;
    static verifyFileListCaptcha = `${environment.acms_api_v1}/public/filelistcheck/0/verify/captcha`;
    static checkCorporateDomainServiceAfter = (corporateId: string | number) => `${environment.acms_server_api_v1}/lawfirm/server/${corporateId}/checkCorporateDomainServiceAfter`;
    static loginsuccess = `${environment.acms_api_v1}/publicapi/login/0/new/ui/loginsuccess`
    static logout = `${environment.acms_api_v1}/app/userlogout/0/logOutServiceAfter`
    static profile = (userId: number, corporateId: number) => `${environment.acms_api_v1}/app/user/0/list/${corporateId}/${userId}/admSingleUserProfileServiceAfter`
    static forgotpass = `${environment.acms_api_v1}/public/forgotpass/0`;
    static resetPassword = `${environment.acms_api_v1}/publicapi/changepassword/0/changepassServiceAfter`;
    static resetPasswordStatus = `${environment.acms_api_v1}/publicapi/changepassword/0/fetch/user/status`;
    static closuresendotp = `${environment.acms_api_v1}/public/user/0/fetch/closure/link`;
    static closuredownloaddata = `${environment.acms_api_v1}/public/user/0/fetch/closure/link/otp/match`;
    static verifierFileUpload = `${environment.acms_api_v1}/public/filelistcheck/0/verify/file/upload`;
    static verifiedFileCheck =  `${environment.acms_api_v1}/public/filelistcheck/0/check/s3/allowed`;
    // static verifierDownloadData = `${environment.acms_api_v1}/public/filelistcheck/0/verify/file/download`;
    static verifierDownloadData = `${environment.acms_api_v1}/public/filelistcheck/0/verify/file/download`;
    // Data Room apis
    static getRoolLevelFolders = `${environment.acms_api_v1}/app/unindexfolderlistg/0/zerolevel/web`;
    static getSubFolders = (folderId: any) => `${environment.acms_api_v1}/app/unindexdoclist/0/-1/${folderId}/DESC%60id/fetchAllAdmFolderChildListResponse`;
    static admFolderListAllServiceAfter = (folderId: any = 0, type: 'S' | 'P' = "S") => `${environment.acms_api_v1}/app/unindexfolderlistg/0/allfolderbytype/${folderId}/${type}/admFolderListAllServiceAfter`;
    static creatRootFolder = `${environment.acms_api_v1}/app/unindexfolderaddauth/0/web/admAddNewFolderServiceAfter`;
    static renameFolder = `${environment.acms_api_v1}/app/unindexdocfolderrename/0/admRenameNewFolderServiceAfter`;
    static deleteFolder = `${environment.acms_api_v1}/app/unindexfolderdeleteg/0/temp/admDeleteFolderServiceAfter`;
    static deleteParmanentlyFolder = (folderId: any) => `${environment.acms_api_v1}/app/unindexfolderdeleteg/0/${folderId}/admDeleteFolderServiceAfter`;
    static restoreFolder = (folderId: any) => `${environment.acms_api_v1}/app/unindexfolderrestorelist/0/${folderId}/admRestoreFolderServiceAfter`;
    static renameFile = `${environment.acms_api_v1}/app/unindexdocfolderrename/0/file/admRenameFileServiceAfter`;
    static admDeleteDocsAfter_temp = `${environment.acms_api_v1}/app/unindexdocdellist/0/admDeleteDocsAfter`;
    static deleteParmanentlyFile = `${environment.acms_api_v1}/app/unindexdocdellist/0/permanent/admDeleteDocsAfter`;
    static restoreFile = `${environment.acms_api_v1}/app/unindexdocrestorelist/0/admrestoreTrashedDocsAfter`;
    static fetchstoragespace = `${environment.acms_api_v1}/app/fetchstoragespace/0/admFetchStorageLeftServiceAfter`;
    static admFetchNotifSettingsServiceAfter = `${environment.acms_api_v1}/app/notifsettings/0/fetch/admFetchNotifSettingsServiceAfter`;
    static admFetchNotifOptionsServiceAfter = `${environment.acms_api_v1}/app/notifmaster/0/fetch/admFetchNotifOptionsServiceAfter`;
    static admUpdateNotifSettingsServiceAfter = `${environment.acms_api_v1}/app/notifsettings/0/update/admUpdateNotifSettingsServiceAfter`;
    static admFetchVersionAfter = (fileId: any) => `${environment.acms_api_v1}/app/unindexdocversionlist/0/${fileId}/admFetchVersionAfter`;
    static admDownloadZipFileAfter = `${environment.acms_api_v1}/app/unindexdocdownload/0/zip/admDownloadZipFileAfter`;
    static admfetchProjectIndexServiceAfter = (folderId: any = 0, type: 'S' | 'P' = "S") => `${environment.acms_api_v1}/app/gen/0/projectindex/${folderId}/${type}/${Intl.DateTimeFormat().resolvedOptions().timeZone.replaceAll(`/`, '-')}/admfetchProjectIndexServiceAfter`;
    static admRebuildProjectIndexServiceAfter = (type: 'S' | 'P' = "S") => `${environment.acms_api_v1}/app/gen/0/projectindex/rebuild/${type}/admRebuildProjectIndexServiceAfter`;
    static admDownloadLogsServiceAfter = `${environment.acms_api_v1}/app/genlogs/0/admDownloadLogsServiceAfter`;
    static admFetchFoldersByParentIdAfter = (folderId: any = 0, type: 'S' | 'P' = "S") => `${environment.acms_api_v1}/app/unindexfolderlistg/0/byparent/${folderId}/${type}/admFetchFoldersByParentIdAfter`;
    static admCopyMoveServiceAfter = `${environment.acms_api_v1}/app/unindexdoccpmv/0/admCopyMoveServiceAfter`;
    static admDisplaySearchFilesServiceAfter = `${environment.acms_api_v1}/app/unindexdocadvsearch/0/admDisplaySearchFilesServiceAfter`;
    static admmakeImportanhtServiceAfter = (fileId: any, flag: 0 | 1) => `${environment.acms_api_v1}/app/unindexdocupdateflagimp/0/${fileId}/${flag}/admmakeImportanhtServiceAfter`;
    static fetchNote = `${environment.acms_api_v1}/app/document/0/notes/fetch/by/docid`;
    static editNote = `${environment.acms_api_v1}/app/document/0/notes/edit`;
    static deleteNote = `${environment.acms_api_v1}/app/document/0/notes/delete/by/id`;
    static addNote = `${environment.acms_api_v1}/app/document/0/notes/add`;
    static sorting = `${environment.acms_api_v1}/app/user/0/update/user/document/sorting`;
    static adminfetchPenidingDeviceServiceAfter = (corporateId: number) => `${environment.acms_api_v1}/app/device/0/pending/${corporateId}/adminfetchPenidingDeviceServiceAfter`;
    static adminfetchBlockedDeviceServiceAfter = (corporateId: number) => `${environment.acms_api_v1}/app/device/0/blocked/${corporateId}/adminfetchBlockedDeviceServiceAfter`;
    static adminfetchApprovedDeviceServiceAfter = (corporateId: number) => `${environment.acms_api_v1}/app/device/0/approved/${corporateId}/adminfetchApprovedDeviceServiceAfter`;
    static blockDevice = `${environment.acms_api_v1}/app/device/0/block`;
    static approveDevice = `${environment.acms_api_v1}/app/device/0/approve`;
    static deleteDevice = `${environment.acms_api_v1}/app/device/0/delete`;
    static adminfetchCorporateDetailsServiceAfter = (lawfirm: number) => `${environment.acms_api_v1}/app/lawfirm/0/${lawfirm}/adminfetchCorporateDetailsServiceAfter`;
    static fetchRegionListData = `${environment.acms_api_v1}/app/gets3regions/0/fetchRegionListData`;
    static disclaimerFetch = `${environment.acms_api_v1}/app/disclaimer/0/fetch`;
    static metatdataFetch = `${environment.acms_api_v1}/app/metatdata/0/master/fetch`;
    static updateSettings = `${environment.acms_api_v1}/app/lawfirm/0/update`;
    static updateDisclaimer = `${environment.acms_api_v1}/app/disclaimer/0/update`;
    static addDisclaimer = `${environment.acms_api_v1}/app/disclaimer/0/add`;
    static metatdataDelete = `${environment.acms_api_v1}/app/metatdata/0/master/delete`;
    static metatdataAdd = `${environment.acms_api_v1}/app/metatdata/0/master/add`;
    static updatelogo = `${environment.acms_api_v1}/app/lawfirm/0/updatelogo`;
    static disclaimerAlltypes = `${environment.acms_api_v1}/app/disclaimer/0/get/alltypes`;
    static admFetchTagsAfter = (fileId: any) => `${environment.acms_api_v1}/app/unindexdoctaglist/0/${fileId}/admFetchTagsAfter`;
    static admAddTagsAfter = `${environment.acms_api_v1}/app/unindexdoctagadd/0/admAddTagsAfter`;
    static admDeleteTagsAfter = (tagId: any) => `${environment.acms_api_v1}/app/unindexdoctagdelete/0/${tagId}/admDeleteTagsAfter`;
    static admShareMgmtInboundListServiceAfter = `${environment.acms_api_v1}/app/unindexinboundharemgmt/0/0/CREATEDDATE/DESC/admShareMgmtInboundListServiceAfter`;
    static admShareMgmtOutboundListServiceAfter = `${environment.acms_api_v1}/app/unindexoutboundharemgmt/0/0/0/CREATEDDATE/DESC/admShareMgmtOutboundListServiceAfter`;
    static admShowFolderFileListInfoServiceAfter = (sharedId: any) => `${environment.acms_api_v1}/app/unindexshareinfo/0/${sharedId}/admShowFolderFileListInfoServiceAfter`;
    static admAllowUploadOutboundShareAfter = (sharedId: any, allowUpload: boolean) => `${environment.acms_api_v1}/app/updateallowdownloadshare/0/allowUpload/0/${sharedId}/${allowUpload ? 1 : 0}/admAllowUploadOutboundShareAfter`;
    static admDeleteOutboundShareAfter = (sharedId: any) => `${environment.acms_api_v1}/app/sharelogindelete/0/0/${sharedId}/admDeleteOutboundShareAfter`;
    static admDeleteInboundShareAfter = (sharedId: any) => `${environment.acms_api_v1}/app/unindexinboundharemgmtdelete/0/${sharedId}/admDeleteInboundShareAfter`;
    static admAllowDownloadOutboundShareAfter = (sharedId: any, allowDownload: boolean) => `${environment.acms_api_v1}/app/updateallowdownloadshare/0/0/${sharedId}/${allowDownload ? 'Y' : 'N'}/admAllowDownloadOutboundShareAfter`;
    static admAllowPrintOutboundShareAfter = (sharedId: any, allowPrint: boolean) => `${environment.acms_api_v1}/app/updateallowprintshare/0/0/${sharedId}/${allowPrint ? 'Y' : 'N'}/admAllowPrintOutboundShareAfter`;
    static admDownloadFolderServiceAfter = (folderId: any) => `${environment.acms_api_v1}/app/unindexdocdownload/0/zipfolder/${folderId}/admDownloadFolderServiceAfter`;
    static generateDynamicLinkServiceAfter = (folderzipid: any) => `${environment.acms_api_v1}/app/unindexdocdownload/0/downloadzipfolder/${folderzipid}/generateDynamicLinkServiceAfter`;
    static admgenerateDownloadFolderLinkAfter = (folderid: any) => `${environment.acms_api_v1}/app/unindexdocdownload/0/async/gendownloadfolderlink/${folderid}/admgenerateDownloadFolderLinkAfter`;
    static admInboundShareURLsServiceAfter = `${environment.acms_api_v1}/app/unindexotpadd/0/admInboundShareURLsServiceAfter`;
    static admShareURLsServiceAfter = `${environment.acms_api_v1}/app/unindexdocshare/0/${getCurrentDateTime()}/admShareURLsServiceAfter`;
    static applyDrmFolder = `${environment.acms_api_v1}/app/drm/6/action/add/by/folder`;
    static deleteDrmFolder = `${environment.acms_api_v1}/app/drm/6/action/delete/records/by/folder`;
    static applyDrmFile = `${environment.acms_api_v1}/app/drm/6/action/add`;
    static deleteDrmFile = `${environment.acms_api_v1}/app/drm/6/action/delete/records`;
    static fetchDrmFile = `${environment.acms_api_v1}/app/drm/6/action/fetch/records`;
    static viewZip = `${environment.acms_api_v1}/app/unindexdocviewer/0/view/zip`;
    static singleFileDetailsAfter = (fileId: any) => `${environment.acms_api_v1}/app/unindexdoclist/0/${fileId}/singleFileDetailsAfter`;
    static viewInZip = `${environment.acms_api_v1}/app/unindexdocviewer/0/view/zip/folder`;
    static viewFileInZip = `${environment.acms_api_v1}/app/unindexdocviewer/0/view/zip/file/inside`;
    static admDisplayDocViewerAfter = (fildeId: any, viewer: VIEWER_TYPE) => `${environment.acms_api_v1}/app/unindexdocviewer/0/${fildeId}/${viewer}/admDisplayDocViewerAfter`;
    static admDisplayDocViewerVersionAfter = (fildeId: any, versionId: any, viewer: VIEWER_TYPE) => `${environment.acms_api_v1}/app/unindexdocviewer/0/version/${fildeId}/${versionId}/${viewer}/admDisplayDocViewerVersionAfter`;
    static newAtc = `${environment.acms_api_v1}/app/unindexfolderaddauth/0/check/web/new/atc`;
    static atc = `${environment.acms_api_v1}/app/unindexgend/0/atc`;
    static admDocAddMetaDataServiceAfter = `${environment.acms_api_v1}/app/unindexdocadd/0/desktop/admDocAddMetaDataServiceAfter`;
    static admGroupListServiceAfter = `${environment.acms_api_v1}/app/usergroups/11/action/fetch`;
    static admFetchFolderSharedSecurityServiceAfter = `${environment.acms_api_v1}/app/usergroups/11/action/fetch/folder/security`;
    static admSaveSharedFolderSecurityServiceAfter = `${environment.acms_api_v1}/app/usergroups/11/action/add/folder/security`
    static admOpenFilePermissionServiceAfter = `${environment.acms_api_v1}/app/usergroups/11/action/fetch/file/security`
    static admSaveFilePermissionServiceAfter = `${environment.acms_api_v1}/app/usergroups/11/action/add/file/security`
    static fetchLiveUsersServiceAfter = `${environment.acms_api_v1}/app/user/0/list/live`
    static fetchUserToBeApprovedServiceAfter = `${environment.acms_api_v1}/app/user/0/draft/fetch/list`
    static displayUserListDataRowsDB = (corporateId: string | number) => `${environment.acms_api_v1}/app/user/0/list/${corporateId}/displayUserListDataRowsDB`
    static adminGroupChangeStatusServiceAfter = `${environment.acms_api_v1}/app/usergroups/11/action/update/status`;
    static adminGroupRenameServiceAfter = `${environment.acms_api_v1}/app/usergroups/11/action/update/name`
    static adminFetchManagerGroupsListAfter = `${environment.acms_api_v1}/app/usergroups/11/action/fetch/mangers`
    static adminMoveGroupsServiceAfter = `${environment.acms_api_v1}/app/usergroups/11/action/move`
    static adminGroupAddServiceAfter = `${environment.acms_api_v1}/app/usergroups/11/action/add`
    static adminSaveManagedGroupsServiceAfter = `${environment.acms_api_v1}/app/usergroups/11/action/add/managed`
    static fetchUserOnlyGroupsServiceAfter = `${environment.acms_api_v1}/app/usergroups/11/action/fetch/usergroup/list`
    static deleteManagedGroupsServiceAfter = `${environment.acms_api_v1}/app/usergroups/11/action/delete/managed`
    static adminGroupDeleteServiceAfter = `${environment.acms_api_v1}/app/usergroups/11/action/delete`
    static adminDisableUserServiceAfter = `${environment.acms_api_v1}/app/user/0/suspend`
    static adminEnableUserServiceAfter = `${environment.acms_api_v1}/app/user/0/reactivate`
    static adminPopulateUserDetailsServiceAfter = (corporateId: number, userId: number) => `${environment.acms_api_v1}/app/user/0/list/${corporateId}/${userId}/adminPopulateUserDetailsServiceAfter`
    static adminDeleteUserServiceAfter = `${environment.acms_api_v1}/app/user/0/suspend/delete/RETAIN`
    static savePasswordServiceAfter = `${environment.acms_api_v1}/app/user/0/resetpassword`
    static adminSendReactivationMailAfter = `${environment.acms_api_v1}/app/user/0/add/userreactvmail/adminSendReactivationMailAfter`
    static adminChangeUserGroupServiceAfter = `${environment.acms_api_v1}/app/usergroups/11/action/change/group`
    static adminAddUserServiceAfter = `${environment.acms_api_v1}/app/user/0/add`
    static adminUpdateUserPictureAfter = `${environment.acms_api_v1}/user/login/update/picture`
    static imortUsersCSV = `${environment.acms_api_v1}/app/user/0/add/import/users`
    static adminAddUserServiceAfterUpdate = `${environment.acms_api_v1}/app/user/0/update`
    static approvePartner = `${environment.acms_api_v1}/app/user/0/draft/approve/partner`
    static rejectPartner = `${environment.acms_api_v1}/app/user/0/draft/reject/partner`
    static exportGroups = `${environment.acms_api_v1}/app/usergroups/11/action/export`
    static adminExportUsers = (corporateId: number) => `${environment.acms_api_v1}/app/user/0/list/${corporateId}/adminExportUsers`
    static disclaimer = `${environment.acms_api_v1}/app/disclaimer/0/update/disclaimer`
    static loginsendotpserviceAfter = `${environment.acms_api_v1}/app/uts/0/gen/loginsendotpserviceAfter`
    static otpCheck = `${environment.acms_api_v1}/app/uts/0/match/loginsuccess`
    static admRegenerateQRCodeServiceAfter = `${environment.acms_api_v1}/app/uts/0/regen/authenticator`
    static admCheckValidpassServiceAfter = `${environment.acms_api_v1}/app/user/0/list/check/valid/user`
    static unlockFile = `${environment.acms_api_v1}/app/unindexdocviewer/0/unlock/view/AI/new`
    static changepassStatusServiceAfter = `${environment.acms_api_v1}/app/user/0/update/change/pass/status`
    static admChangeNotifStatusServiceAfter = `${environment.acms_api_v1}/app/user/0/update/change/notif/status`
    static corporateExpiryDate = (corporateId: any) => `${environment.acms_api_v1}/publicapi/login/0/fetch/corporate/expirydate/${corporateId}`
    static dcirrusDisclaimer = `${environment.acms_api_v1}/app/disclaimer/0/update/disclaimer/dcirrus`
    static recordsPerPage = `${environment.acms_api_v1}/app/user/0/update/user/per/page`

    static admindexDocumentsServiceAfter = `${environment.acms_api_v1}/app/unindexdocviewer/0/index/create`;

    static fetchIpRangeList = `${environment.acms_api_v1}/app/ip/0/fetch`;
    static addIpRange = `${environment.acms_api_v1}/app/ip/0/add`;
    static deleteIpRange = `${environment.acms_api_v1}/app/ip/0/delete`;
    static importIpRange = `${environment.acms_api_v1}/app/ip/0/import`;

    static loginsuccessuser = `${environment.acms_api_v1}/publicapi/login/0/validate/user/login`
    static updateDCirrusDisclaimerLogin = `${environment.acms_api_v1}/publicapi/login/0/update/dcirrus/disclaimer`

    //single login multiple corporate user related new APIS
    static userCorpList = `${environment.acms_api_v1}/user/login/fetch/corp/list`
    static resetPasswordLogin = `${environment.acms_api_v1}/user/login/change/password`;
    static logoutURL = `${environment.acms_api_v1}/user/login/logout`
    static makeFavCorporate = `${environment.acms_api_v1}/user/login/make/favorite`
    static updateUserLoginRecord = `${environment.acms_api_v1}/user/login/update`
    static fetchUserLoginStatus = `${environment.acms_api_v1}/publicapi/changepassword/0/fetch/user/login/status`
    static userLoginRecord = `${environment.acms_api_v1}/user/login/fetch/record`
    static activateUserStatus = `${environment.acms_api_v1}/publicapi/changepassword/0/activate/user/status`
    static activateUserLoginStatus = `${environment.acms_api_v1}/publicapi/changepassword/0/activate/user/login/status`
    static displayDocViewerURL = `${environment.acms_api_v1}/user/login/viewer/doc`;
    static reactivateCorpUserService = `${environment.acms_api_v1}/user/login/reactivate/corp/user`

    //display type set
    static updateDisplayType = `${environment.acms_api_v1}/app/user/0/update/display/type`    
    // Dashboard APIS
    static fetchUsageStatisticsServiceAfter = `${environment.acms_api_v1}/app/spc/0/fetch/usage/statistics`;

    // Dashboard V2 APIS
    static dataAllotted = `${environment.acms_api_v1}/app/dashboard/17/total/data/allotted`;
    static usersAllotted = `${environment.acms_api_v1}/app/dashboard/17/total/user/allotted`;
    static mostActiveUsers = `${environment.acms_api_v1}/app/dashboard/17/most/active/users`;
    static mostViewedFiles = `${environment.acms_api_v1}/app/dashboard/17/most/viewed/files`;
    static mostDownloadedFiles = `${environment.acms_api_v1}/app/dashboard/17/most/downloaded/files`;
    static mostDownloadedFolders = `${environment.acms_api_v1}/app/dashboard/17/most/downloaded/folders`;
    static latestFolders = `${environment.acms_api_v1}/app/dashboard/17/latest/folders`;
    static latestUploadedFiles = `${environment.acms_api_v1}/app/dashboard/17/latest/uploaded/files`;
    static totalFolders = `${environment.acms_api_v1}/app/dashboard/17/total/folders`;
    static totalFoldersDownloaded = `${environment.acms_api_v1}/app/dashboard/17/total/folders/downloaded`;
    static totalFilesUploaded = `${environment.acms_api_v1}/app/dashboard/17/total/files/uploaded`;
    static totalFilesDownloaded = `${environment.acms_api_v1}/app/dashboard/17/total/files/downloaded`;
    static totalFilesViewed = `${environment.acms_api_v1}/app/dashboard/17/total/files/viewed`;
    static totalFilesDeleted = `${environment.acms_api_v1}/app/dashboard/17/total/files/deleted`;
    static downloadUploadFilesGraph = `${environment.acms_api_v1}/app/dashboard/17/graph/files/dn/up`;
    static downloadUploadSizeGraph = `${environment.acms_api_v1}/app/dashboard/17/graph/files/dn/up/size`;
    static totalDownloaded = `${environment.acms_api_v1}/app/dashboard/17/total/downloaded`;
    static totalUploaded = `${environment.acms_api_v1}/app/dashboard/17/total/uploaded`;
}
